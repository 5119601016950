import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { COLOR_CODE_NEW, TRANSLATIONS } from 'types/enums';
import './LabelledInputWithImage.scss';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import Input from '../Input';
import * as styles from './LabelledInputWithImageStyles';

type Props = {
  label?: string;
  placeholder?: string;
  value?: any;
  onChange: (e: any)=>void;
  containerStyle? :CSSProperties;
  inputStyle?: any;
  icon?: string;
  type?:string;
  dropdownOptions?: { value: string, text: string }[],
  disabled?:boolean;
  dateFormat?: string;
  maxDate?: Date;
  datePickerColor?: string;
  showDatePickerError?: boolean;
  labelStyle?: CSSProperties;
  errorText?: string;
  showError?: boolean;
  inputHeight?: string;
  inputWidth?: string;
  inputLabelStyle?: any;
};

const LabelledInputWithImage = (props: Props) => {
  const { t } = useTranslation();
  const {
    icon,
    label,
    value,
    onChange,
    placeholder,
    disabled,
    containerStyle,
    inputStyle,
    type,
    dropdownOptions,
    dateFormat,
    maxDate,
    datePickerColor,
    showDatePickerError,
    labelStyle,
    errorText,
    showError = false,
    inputHeight,
    inputWidth,
    inputLabelStyle,
  } = props;

  const customDatePickerTheme = createTheme({
    palette: {
      primary: {
        main: datePickerColor || COLOR_CODE_NEW.PRIMARY,
      },
    },
  });
  return (
    <div className="inputContainer" style={{ ...(containerStyle || {}) }}>
      {(label || icon) && (
      <div className="labelContainer" style={{ ...(labelStyle || {}) }}>
        {icon && <img className="icon" src={icon} alt="input" />}
        {label && <span>{label || ''}</span>}
      </div>
      )}
      {type !== 'dropdown' && type !== 'datepicker'
      && (
        <div>
          <Input
            disabled={disabled}
            value={value}
            placeholder={placeholder || ''}
            activeBoxShadow="none"
            activeBorderColor={COLOR_CODE_NEW.PRIMARY}
            inactiveBorderColor={COLOR_CODE_NEW.BORDERS}
            onChange={onChange}
            style={{
              border: `2px solid ${COLOR_CODE_NEW.BORDERS}`,
              maxWidth: 'unset',
              paddingTop: '5px',
              paddingBottom: '5px',
              ...(inputStyle || {}),
            }}
          />
          {showError && (<span style={{ fontSize: '14px', color: '#C00000' }}>{errorText}</span>)}
        </div>
      )}
      {type === 'dropdown' && dropdownOptions && (
      <select
        style={{
          border: `2px solid ${COLOR_CODE_NEW.BORDERS}`,
          maxWidth: 'unset',
          paddingTop: '5px',
          paddingBottom: '5px',
          ...(inputStyle || {}),
        }}
        value={value}
        onChange={onChange}
      >
        <option value="" selected disabled hidden>{placeholder ?? t(TRANSLATIONS.CHOOSE_HERE)}</option>
        {dropdownOptions.map(option => (
          <option
            key={option.value}
            disabled={option.value.length === 0}
            value={option.value}
          >{option.text}
          </option>
        ))}
      </select>
      ) }
      {type === 'datepicker' && (
        <MuiThemeProvider theme={customDatePickerTheme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={value}
              onChange={onChange}
              format={dateFormat}
              label={placeholder || ''}
              sx={{
                ...styles.datePicker,
                ...(inputStyle || {}),
                '& .MuiOutlinedInput-root': {
                  height: inputHeight ?? '40px',
                  width: inputWidth ?? '150px',
                },
                '& .MuiInputLabel-root' : {
                  top: inputLabelStyle?.top ?? '0px',
                },
              }}
              maxDate={maxDate}
              slotProps={{
                textField: {
                  error: showDatePickerError,
                },
              }}
            />
          </LocalizationProvider>
        </MuiThemeProvider>
      )}
    </div>
  );
};

export default LabelledInputWithImage;
