import React, { useEffect, useMemo, useState } from 'react';
import './RouteDetails.scss';
import { useTranslation } from 'react-i18next';
import ImageLinks from 'utils/ImageLinks';
import ChartTabs from 'components/Tabs/ChartTabs';
import { useNavigate, useLocation } from 'react-router-dom';
import { ROAD_SAFETY_ROUTE_STATUS, TRANSLATIONS } from 'types/enums';
import { format } from 'date-fns';
import LoadingSpinner from 'components/LoadingSpinner';
import { useGetRouteSafetyChecklistAnswerByRouteIdLazyQuery } from 'generated/graphql';
import { capitalizeFirst } from 'utils/stringHelpers';
import GoogleMapScreen from './component/GoogleMapScreen';

const RouteDetails = () => {
  const { t } = useTranslation();

  const location: any = useLocation();

  const routeData = location?.state?.routeData;

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const [callGetRouteSafetyChecklistAnswerByRouteIdLazyQuery,
    { data, loading }] = useGetRouteSafetyChecklistAnswerByRouteIdLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const routeAnswerData = useMemo(() => {
    const answers = data?.getRouteSafetyChecklistAnswerByRouteId?.answers;
    return answers;
  }, [data]);

  const callGetRouteSafetyChecklistAnswerByRouteIdApi = () => {
    callGetRouteSafetyChecklistAnswerByRouteIdLazyQuery({
      variables: {
        routeId: Number(routeData?.content?.metadata?.route?.routeId),
      },
    });
  };

  const routeMappedData = useMemo(() => {
    let arr: any = [];
    if (routeAnswerData?.length) {
      arr = routeAnswerData?.map((route: any) => ({
        [route?.createdAt]: route,
      }));
    }
    return arr;
  }, [routeAnswerData]);

  const routeDatesData = useMemo(() => {
    let arr: any = [];
    if (routeMappedData?.length) {
      arr = routeMappedData?.map((item: any) => Object.keys(item)[0]);
    }
    return arr;
  }, [routeMappedData]);

  useEffect(() => {
    callGetRouteSafetyChecklistAnswerByRouteIdApi();
  // eslint-disable-next-line
  }, []);

  const tabs = [
    { title: t(TRANSLATIONS.ROUTE_DATA), value: '', type: t(TRANSLATIONS.ROUTE_DATA), index: 0 },
    // { title: t(TRANSLATIONS.GENERAL_INFORMATION), value: '', type: t(TRANSLATIONS.GENERAL_INFORMATION), index: 1 },
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1);
  };

  const handleLeftClick = () => {
    setCurrentIndex(prevIndex => Math.max(prevIndex - 1, 0));
  };

  const handleRightClick = () => {
    setCurrentIndex(prevIndex => Math.min(prevIndex + 1, routeDatesData?.length - 1));
  };

  const selectedChecklistData: any = useMemo(() => {
    return Object.values(routeMappedData?.[currentIndex] ?? {})?.[0];
  }, [currentIndex, routeMappedData]);

  return (
    <div className="routeDetails">
      <div className="routeDetails__headerContainer">
        <div onClick={navigateBack} role="button" className="routeDetails__headerContainer__wrap">
          <img src={ImageLinks.leftArrowIcon} alt="icon" className="routeDetails__headerContainer__wrap__backArrow" />
          <p className="routeDetails__headerContainer__wrap__returnTitle">{t(TRANSLATIONS.RETURN_TO_CRITICAL_PATHS)}</p>
        </div>
      </div>
      <div className="routeDetails__bodyContainer">
        <div style={{ width: '45rem' }}>
          <div className="routeDetails__bodyContainer__routeDetail">
            <p className="routeDetails__bodyContainer__routeDetail__title">{`${t(TRANSLATIONS.ROUTE)} ${routeData?.content?.metadata?.route?.externalId ?? ''}`}</p>
            {routeData?.status && (routeData?.status !== ROAD_SAFETY_ROUTE_STATUS.OK) ? (
              routeData?.status === ROAD_SAFETY_ROUTE_STATUS.AT_RISK ? (
                <div className="routeDetails__bodyContainer__routeDetail__atRiskStatusContainer">
                  <p className="routeDetails__bodyContainer__routeDetail__atRiskStatusContainer__status">{t(TRANSLATIONS.AT_RISK) ?? ''}</p>
                </div>
              ) : (
                <div className="routeDetails__bodyContainer__routeDetail__statusContainer">
                  <p className="routeDetails__bodyContainer__routeDetail__statusContainer__status">{t(TRANSLATIONS.CRITICAL) ?? ''}</p>
                </div>
              )
            ) : null}
          </div>
          <div className="routeDetails__bodyContainer__tabsDetail">
            <ChartTabs
              tabsItems={tabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab as any}
              selectedTabStyle="routeDetails__bodyContainer__tabsDetail__selected-tab"
              customStyle="routeDetails__bodyContainer__tabsDetail__tabs-container"
            />
            <div className="routeDetails__bodyContainer__tabsDetail__dateDetail" style={selectedTab?.index === 1 ? { pointerEvents: 'none', opacity: '0.5' } : {}}>
              <div onClick={handleLeftClick} role="button">
                <img src={ImageLinks.chevronLeftV1} alt="icon" className={`routeDetails__bodyContainer__tabsDetail__dateDetail__leftArrow ${currentIndex === 0 ? 'chevron_disabled' : ''}`} />
              </div>
              <p className="routeDetails__bodyContainer__tabsDetail__dateDetail__title">{format(routeDatesData?.[currentIndex] ? new Date(routeDatesData?.[currentIndex]) : new Date(), 'd MMM yyyy')}</p>
              <div onClick={handleRightClick} role="button">
                <img src={ImageLinks.chevronRightV1} alt="icon" className={`routeDetails__bodyContainer__tabsDetail__dateDetail__rightArrow ${Object.keys(routeMappedData)?.length - 1 === currentIndex ? 'chevron_disabled' : ''}`} />
              </div>
            </div>
          </div>
          {loading ? (
            <LoadingSpinner positionTop={250} />
          ) : (
            selectedTab?.index === 0 ? (
              <div className="routeDetails__bodyContainer__routeInfo">
                {selectedChecklistData ? (
                  <>
                    <div className="routeDetails__bodyContainer__routeInfo__userInfoDetails">
                      <div className="routeDetails__bodyContainer__routeInfo__userInfoDetails__userInfo">
                        <img src={selectedChecklistData?.answeredByUser?.picture ?? `https://ui-avatars.com/api/?name=${selectedChecklistData?.answeredByUser?.name}&color=ff0000`} alt="icon" className="routeDetails__bodyContainer__routeInfo__userInfoDetails__userInfo__profileImage" />
                        <div className="routeDetails__bodyContainer__routeInfo__userInfoDetails__userInfo__nameRole">
                          <p className="routeDetails__bodyContainer__routeInfo__userInfoDetails__userInfo__nameRole__name">{selectedChecklistData?.content?.metadata?.driver?.name ?? ''}</p>
                          <p className="routeDetails__bodyContainer__routeInfo__userInfoDetails__userInfo__nameRole__role">{t(TRANSLATIONS.DRIVER)}</p>
                        </div>
                      </div>
                      <div className="routeDetails__bodyContainer__routeInfo__userInfoDetails__truckInfo">
                        <img src={ImageLinks.truck3} alt="icon" className="routeDetails__bodyContainer__routeInfo__userInfoDetails__truckInfo__truckImage" />
                        <div className="routeDetails__bodyContainer__routeInfo__userInfoDetails__truckInfo__nameRole">
                          <p className="routeDetails__bodyContainer__routeInfo__userInfoDetails__truckInfo__nameRole__name">{selectedChecklistData?.content?.metadata?.truck?.sapNumber ?? selectedChecklistData?.content?.metadata?.truck?.plateNumber}</p>
                          <p className="routeDetails__bodyContainer__routeInfo__userInfoDetails__truckInfo__nameRole__role">{t(TRANSLATIONS.TRUCK)}</p>
                        </div>
                      </div>
                    </div>
                    <div className="routeDetails__bodyContainer__routeInfo__destinationDetails">
                      <div className="routeDetails__bodyContainer__routeInfo__destinationDetails__evaluator">
                        <p className="routeDetails__bodyContainer__routeInfo__destinationDetails__evaluator__title">{t(TRANSLATIONS.EVALUATOR)}</p>
                        <div className="routeDetails__bodyContainer__routeInfo__destinationDetails__evaluator__nameDetails">
                          <img src={ImageLinks.fileGood} alt="icon" className="routeDetails__bodyContainer__routeInfo__destinationDetails__evaluator__nameDetails__icon" />
                          <p className="routeDetails__bodyContainer__routeInfo__destinationDetails__evaluator__nameDetails__name">{selectedChecklistData?.content?.metadata?.evaluator?.name ?? ''}</p>
                        </div>
                      </div>
                      <div className="routeDetails__bodyContainer__routeInfo__destinationDetails__destination">
                        <p className="routeDetails__bodyContainer__routeInfo__destinationDetails__destination__title">{t(TRANSLATIONS.DESTINATION)}</p>
                        <div className="routeDetails__bodyContainer__routeInfo__destinationDetails__destination__nameDetails">
                          <img src={ImageLinks.locationV1} alt="icon" className="routeDetails__bodyContainer__routeInfo__destinationDetails__destination__nameDetails__icon" />
                          <p className="routeDetails__bodyContainer__routeInfo__destinationDetails__destination__nameDetails__name">{selectedChecklistData?.content?.metadata?.destination ?? ''}</p>
                        </div>
                      </div>
                    </div>
                    {selectedChecklistData?.content?.checklist?.map((c: any) => (
                      c?.items?.map((i: any) => {
                        let title = '';
                        let answer = '';
                        let icon = '';
                        let showDivider = false;
                        switch (i?.title?.replace?.(/^\d+\. /, '')) {
                          case 'Distancia média (Ida)':
                            title = 'Distancia media - ida';
                            answer = `${typeof i?.bits[0]?.answer === 'string' || typeof i?.bits[0]?.answer === 'number' ? i?.bits[0]?.answer : ''} km`;
                            icon = ImageLinks.arrowRightV1;
                            showDivider = false;
                            break;
                          case 'Distancia média (Vuelta)':
                            title = 'Distancia media - vuelta';
                            answer = `${typeof i?.bits[0]?.answer === 'string' || typeof i?.bits[0]?.answer === 'number' ? i?.bits[0]?.answer : ''} km`;
                            icon = ImageLinks.arrowLeftV1;
                            showDivider = true;
                            break;
                          case 'Tiempo medio de viaje (Ida)':
                            title = 'Tiempo medio - ida';
                            answer = typeof i?.bits[0]?.answer === 'string' || typeof i?.bits[0]?.answer === 'number' ? `${i?.bits[0]?.answer} h` : '';
                            icon = ImageLinks.clockChevronRight;
                            showDivider = false;
                            break;
                          case 'Tiempo medio de viaje (Vuelta)':
                            title = 'Tiempo medio - vuelta';
                            answer = typeof i?.bits[0]?.answer === 'string' || typeof i?.bits[0]?.answer === 'number' ? `${i?.bits[0]?.answer} h` : '';
                            icon = ImageLinks.clockChevronLeft;
                            showDivider = false;
                            break;
                          case 'Cantidad de puntos de parada observados':
                            title = 'Cantidad de puntos de parada observados';
                            answer = `${typeof i?.bits[0]?.answer === 'string' || typeof i?.bits[0]?.answer === 'number' ? i?.bits[0]?.answer : ''}`;
                            icon = ImageLinks.locationBlack;
                            showDivider = true;
                            break;
                          case 'Inexistencia de Señalización horizontal':
                            title = 'Inexistencia de Señalización horizontal';
                            answer = `${typeof i?.bits[0]?.answer === 'string' || typeof i?.bits[0]?.answer === 'number' ? i?.bits[0]?.answer : ''}`;
                            icon = ImageLinks.listHorizontal;
                            showDivider = false;
                            break;
                          case 'Inexistencia de Señalización vertical':
                            title = 'Inexistencia de Señalización vertical';
                            answer = `${typeof i?.bits[0]?.answer === 'string' || typeof i?.bits[0]?.answer === 'number' ? i?.bits[0]?.answer : ''}`;
                            icon = ImageLinks.list;
                            showDivider = false;
                            break;
                          case 'Largos trechos en mal estado':
                            title = 'Largos tramos en mal estado';
                            answer = `${typeof i?.bits[0]?.answer === 'string' || typeof i?.bits[0]?.answer === 'number' ? i?.bits[0]?.answer : ''}`;
                            icon = ImageLinks.roadDamaged;
                            showDivider = true;
                            break;
                          case 'Largos trechos en declive':
                            title = 'Largas secciones inclinadas';
                            answer = `${typeof i?.bits[0]?.answer === 'string' || typeof i?.bits[0]?.answer === 'number' ? i?.bits[0]?.answer : ''}`;
                            icon = ImageLinks.roadInclined;
                            showDivider = false;
                            break;
                          case 'Largos trechos en recta':
                            title = 'Largas secciones rectas';
                            answer = `${typeof i?.bits[0]?.answer === 'string' || typeof i?.bits[0]?.answer === 'number' ? i?.bits[0]?.answer : ''}`;
                            icon = ImageLinks.roadLarge;
                            showDivider = false;
                            break;
                          case 'Alta incidencia de curvas peligrosas':
                            title = 'Alta incidencia de curvas peligrosas';
                            answer = `${typeof i?.bits[0]?.answer === 'string' || typeof i?.bits[0]?.answer === 'number' ? i?.bits[0]?.answer : ''}`;
                            icon = ImageLinks.roadCurved;
                            showDivider = true;
                            break;
                          case 'Largos trechos en pista simple':
                            title = 'Largos tramos en una sola pista';
                            answer = `${typeof i?.bits[0]?.answer === 'string' || typeof i?.bits[0]?.answer === 'number' ? i?.bits[0]?.answer : ''}`;
                            icon = ImageLinks.roadLarge;
                            showDivider = false;
                            break;
                          case 'Largos tramos de pista en montañas':
                            title = 'Largos tramos de pista en Sierras/Montañas';
                            answer = `${typeof i?.bits[0]?.answer === 'string' || typeof i?.bits[0]?.answer === 'number' ? i?.bits[0]?.answer : ''}`;
                            icon = ImageLinks.mountains;
                            showDivider = false;
                            break;
                          case 'Mayor parte de carretera sin policia acostado':
                            title = 'Mayor parte de carretera sin arcén / hombro';
                            answer = `${typeof i?.bits[0]?.answer === 'string' || typeof i?.bits[0]?.answer === 'number' ? i?.bits[0]?.answer : ''}`;
                            icon = ImageLinks.roadWithoutShoulder;
                            showDivider = false;
                            break;
                          case 'Largos trechos de carretera con desnivel entre pista y acostamiento':
                            title = 'Largos tramos de carretera con desniveles entre carril y arcén';
                            answer = `${typeof i?.bits[0]?.answer === 'string' || typeof i?.bits[0]?.answer === 'number' ? i?.bits[0]?.answer : ''}`;
                            icon = ImageLinks.roadUneven;
                            showDivider = true;
                            break;
                          case 'Largos trechos con Neblinas':
                            title = 'Largos tramos con nieblas';
                            answer = `${typeof i?.bits[0]?.answer === 'string' || typeof i?.bits[0]?.answer === 'number' ? i?.bits[0]?.answer : ''}`;
                            icon = ImageLinks.fog;
                            showDivider = false;
                            break;
                          case 'Alta frecuencia de los  vehículos (carros e camiones)':
                            title = 'Alta frecuencia de los  vehículos (carros e camiones)';
                            answer = `${typeof i?.bits[0]?.answer === 'string' || typeof i?.bits[0]?.answer === 'number' ? i?.bits[0]?.answer : ''}`;
                            icon = ImageLinks.truckV1;
                            showDivider = false;
                            break;
                          case 'Largo tramo de carretera en construcción / duplicación':
                            title = 'Largos tramos de la carretera en obras/duplicaciones';
                            answer = `${typeof i?.bits[0]?.answer === 'string' || typeof i?.bits[0]?.answer === 'number' ? i?.bits[0]?.answer : ''}`;
                            icon = ImageLinks.construction;
                            showDivider = false;
                            break;
                          case 'Animales en la pista':
                            title = 'Animales en la pista';
                            answer = `${typeof i?.bits[0]?.answer === 'string' || typeof i?.bits[0]?.answer === 'number' ? i?.bits[0]?.answer : ''}`;
                            icon = ImageLinks.animal;
                            showDivider = false;
                            break;
                          default:
                            title = '';
                            answer = '';
                            showDivider = false;
                        }
                        if (title) {
                          return (
                            <div>
                              <div className="routeDetails__bodyContainer__routeInfo__destinationInfo">
                                <div className="routeDetails__bodyContainer__routeInfo__destinationInfo__nameDetails">
                                  <img src={icon} alt="icon" className="routeDetails__bodyContainer__routeInfo__destinationInfo__nameDetails__icon" />
                                  <p className="routeDetails__bodyContainer__routeInfo__destinationInfo__nameDetails__name">{title ?? ''}</p>
                                </div>
                                <p className="routeDetails__bodyContainer__routeInfo__destinationInfo__distanceInfo">
                                  {answer}
                                </p>
                              </div>
                              {showDivider ? (
                                <div className="routeDetails__bodyContainer__routeInfo__divider" />
                              ) : null}
                            </div>
                          );
                        }
                        return <></>;
                      })
                    ))}
                    {selectedChecklistData?.content?.metadata?.images?.length ? (
                      <div className="routeDetails__bodyContainer__routeInfo__divider" />
                    ) : null}
                    {selectedChecklistData?.content?.metadata?.images?.map((i: any) => (
                      <div className="routeDetails__bodyContainer__routeInfo__imageContainer">
                        <img src={i?.url} alt="icon" className="routeDetails__bodyContainer__routeInfo__imageContainer__image" />
                        <div className="routeDetails__bodyContainer__routeInfo__imageContainer__details">
                          <p className="routeDetails__bodyContainer__routeInfo__imageContainer__details__name">{capitalizeFirst(i?.title) ?? ''}</p>
                          <p className="routeDetails__bodyContainer__routeInfo__imageContainer__details__distance">{capitalizeFirst(i?.description) ?? ''}</p>
                        </div>
                      </div>
                    ))}
                  </>
                ) : null}
              </div>
            ) : (
              <div className="routeDetails__bodyContainer__mapInfo">
                <GoogleMapScreen
                  // startLocation={startLocation}
                  // endLocation={endLocation}
                  height="241px"
                />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default RouteDetails;
