import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageLinks from 'utils/ImageLinks';
import Modal from 'components/Modal';
import { toast } from 'react-toastify';
import { COLOR_CODE_NEW, TRANSLATIONS, UserTypes } from 'types/enums';
import LabelledInputWithImage from 'components/Input/LabelledInputWithImage/LabelledInputWithImage';
import BasicButton from 'components/Button/BasicButton';
import request from 'utils/request';
import LoadingSpinner from 'components/LoadingSpinner';
import locationIcon from 'assets/icons/location.svg';
import CheckBoxWithIcon from 'components/CheckBoxWithIcon';

import { useGetAllLocationsLazyQuery } from 'generated/graphql';
import './AddUserModal.scss';

type UserInfoType = {
  position: string;
  location: string;
  name: string;
};

type Props = {
  onAdd: (data: UserInfoType) => void,
  closeModal: () => void;
  selectedLocation: {
    text: string;
    value: string;
    type: string;
  }
};

const AddUserModal = (props: Props) => {
  const { t } = useTranslation();

  const permissionsOptions = [
    {
      id: 31,
      label: `${t(TRANSLATIONS.CERTIFICATES)}`,
      type: UserTypes.CertificatesOnly,
    },
    {
      id: 32,
      label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)} ${t(TRANSLATIONS.INCIDENTS)}, ${t(TRANSLATIONS.ACTION_LOGS)}`,
      type: UserTypes.ReporterOnlyDPO,
    },
  ];
  const [invalidFields, setInvalidFields] = useState([] as string[]);
  const { closeModal, onAdd, selectedLocation } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [positionsForBu, setPositionsForBu] = useState<any>([]);
  const [locations, setLocations] = useState<any>([]);
  const [userInfo, setUserInfo] = useState<UserInfoType>({ position: '', location: '', name: '' });
  const [selectedRole, setSelectedRole] = useState<any>(null);
  const [showRoleOptions, setShowRoleOptions] = useState<boolean>(false);
  const [showStartWorkingDayOption, setShowStartWorkingDayOption] = useState<boolean>(false);
  const [selectedStartWorkingDay, setSelectedStartWorkingDay] = useState<any>(
    null,
  );

  function compareFn(a: any, b: any) {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  }

  const [callGetAllLocations] = useGetAllLocationsLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (res: any) => {
      const locationArray = res.getLocations
        ?.map((loc: any) => ({ text: loc?.name, value: loc?.slug, type: loc?.type }));
      locationArray.sort(compareFn);
      setLocations(locationArray);
      setLoading(false);
    },
    onError: () => {
      toast.error(t(TRANSLATIONS.SOMETHING_WENT_WRONG_TRY_LATER));
      setLoading(false);
      closeModal();
    },
  });

  const submitHandler = async () => {
    setInvalidFields([]);
    const invalidFieldsTemp = [];
    if (userInfo.name.trim().length < 3) {
      invalidFieldsTemp.push('firstName');
    }
    if (!userInfo.position) {
      invalidFieldsTemp.push('position');
    }
    if (!userInfo.location && !selectedLocation?.value) {
      invalidFieldsTemp.push('location');
    }
    if (showStartWorkingDayOption && !!isNaN(selectedStartWorkingDay?.getTime())) {
      invalidFieldsTemp.push('startWorkingDay');
    }
    if (invalidFieldsTemp.length > 0) {
      setInvalidFields(invalidFieldsTemp);
      return;
    }
    setLoading(true);
    try {
      const data = await request.post(`/api/v1/distribution-centers/${userInfo.location}/users`, {
        name: userInfo.name,
        position: userInfo.position === t(TRANSLATIONS.WITHOUT_POSITION) ? '' : userInfo.position,
        type: UserTypes.CertificatesOnly,
        createUserAccessEntry: true,
        roleId: selectedRole ?? 31,
        startWorkingDay: selectedStartWorkingDay
          ? new Date(selectedStartWorkingDay)
          : null,
      });
      if (data) {
        const locationName = locations.find((loc: any) => loc.value === userInfo.location);
        const createdUserInfo = {
          position: userInfo.position,
          location: locationName?.text,
          name: userInfo.name,
          pin: data?.user?.pin,
          shortSlug: data?.user?.shortSlug,
        };
        setLoading(false);
        if (onAdd) {
          onAdd(createdUserInfo);
        }
      } else {
        toast.error(TRANSLATIONS.SOMETHING_WENT_WRONG_TRY_LATER);
        setLoading(false);
        closeModal();
      }
    } catch (e) {
      toast.error(TRANSLATIONS.SOMETHING_WENT_WRONG_TRY_LATER);
      console.error(e);
      setLoading(false);
      closeModal();
    }
  };

  const onChangeFirstName = (e: any) => {
    setUserInfo({
      ...userInfo,
      name: e.target.value,
    });
  };

  const onChangePosition = (e: any) => {
    setUserInfo({
      ...userInfo,
      position: e.target.value,
    });
  };

  const onChangeLocation = async (e: any) => {
    const selectedValue = e?.target?.value || selectedLocation?.value;
    if (!selectedValue) {
      return;
    }
    const selectLocation = (locations?.find((loc: any) => loc.value === e?.target?.value)
    || selectedLocation);
    if (selectLocation?.type === 'DISTRIBUTION_CENTER') {
      setShowRoleOptions(true);
      setSelectedStartWorkingDay(null);
      setShowStartWorkingDayOption(true);
    } else {
      setSelectedRole(null);
      setSelectedStartWorkingDay(null);
      setShowRoleOptions(false);
      setShowStartWorkingDayOption(false);
    }
    setLoading(true);
    setUserInfo({
      ...userInfo,
      position: '',
      location: selectLocation.value,
    });
    try {
      const response = await request.get(`/api/v1/positions-by-bu?cdSlug=${selectedValue}`);
      const positions = response?.positions?.map(({ position }: { position: string }) => (
        { text: position, value: position }),
      );
      if (positions.length === 0) {
        positions.push(
          { text: t(TRANSLATIONS.WITHOUT_POSITION), value: t(TRANSLATIONS.WITHOUT_POSITION) },
        );
      }
      positions.sort(compareFn);
      setPositionsForBu(positions);
      setLoading(false);
    } catch (err) {
      console.error(err);
      toast.error(TRANSLATIONS.SOMETHING_WENT_WRONG_TRY_LATER);
      setLoading(false);
      closeModal();
    }
  };

  useEffect(() => {
    setLoading(true);
    onChangeLocation(selectedLocation);
    callGetAllLocations();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  const handleStartWorkingDayChange = (value: any) => {
    if (value) {
      const formattedDate = new Date(value?.getFullYear(), value?.getMonth(), value?.getDate());
      setSelectedStartWorkingDay(formattedDate);
    }
  };

  if (loading) {
    return (
      <Modal modalContainerStyles={{
        width: '60vw',
        left: '20%',
        maxHeight: '80vh',
        minHeight: '30vh',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
      }}
      >
        <LoadingSpinner />
      </Modal>
    );
  }

  const onChangeRole = (roleId: any) => {
    setSelectedRole(roleId);
  };

  return (
    <div className="addUser">
      <Modal
        modalContainerStyles={{ width: '60vw',
          left: '20%',
          maxHeight: '90vh',
          minHeight: '20vh',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)' }}
        onOverlayClick={closeModal}
      >
        <div className="addUser__header">
          <div className="addUser__header__userNameAndIcon">
            <span>{t(TRANSLATIONS.ADD_USER_TO_DC)}</span>
          </div>
          <div className="addUser__header__userIcon">
            <img
              src={ImageLinks.userIcon}
              alt="UserPicture"
            />
          </div>
        </div>
        <div className="addUser__name">
          <LabelledInputWithImage
            onChange={e => onChangeFirstName(e)}
            placeholder={t(TRANSLATIONS.ENTER_NAME)}
            label={`${t(TRANSLATIONS.NAME)}(s)*`}
            value={userInfo.name}
            inputStyle={invalidFields.includes('firstName') ? {
              borderColor: COLOR_CODE_NEW.PRIMARY,
            } : {}}
          />
          {(invalidFields.includes('firstName') && !userInfo.name) && (
            <span className="addUser__name__input--error">{t(TRANSLATIONS.ENTER_NAME)}</span>
          )}
        </div>
        <div className="addUser__location">
          <LabelledInputWithImage
            inputStyle={invalidFields.includes('location') ? {
              borderColor: COLOR_CODE_NEW.PRIMARY,
              width: '250px',
            } : { width: '250px' }}
            onChange={e => onChangeLocation(e)}
            icon={locationIcon}
            placeholder={t(TRANSLATIONS.LOCATION)}
            label={t(TRANSLATIONS.LOCATION)}
            value={userInfo.location || selectedLocation?.value}
            dropdownOptions={locations || []}
            type="dropdown"
          />
          {(invalidFields.includes('location') && !userInfo.location && !selectedLocation?.value) && (
            <span className="addUser__location__input--error">{t(TRANSLATIONS.SELECT_LOCATION)}</span>
          )}
        </div>
        {(userInfo.location || selectedLocation) && (
          <div className="addUser__position">
            <LabelledInputWithImage
              inputStyle={invalidFields.includes('position') ? {
                borderColor: COLOR_CODE_NEW.PRIMARY,
                width: '250px',
              } : { width: '250px' }}
              onChange={e => onChangePosition(e)}
              icon={ImageLinks.positionIcon}
              placeholder={t(TRANSLATIONS.POSITION)}
              label={t(TRANSLATIONS.POSITION)}
              value={userInfo.position}
              dropdownOptions={positionsForBu || []}
              type="dropdown"
            />
            {(invalidFields.includes('position') && !userInfo.position) && (
              <span className="addUser__position__input--error">{t(TRANSLATIONS.SELECT_POSITION)}</span>
            )}
          </div>
        )}
        {showStartWorkingDayOption && (
          <>
            <div className="addUser__startWorkingDay">
              <LabelledInputWithImage
                label={t(TRANSLATIONS.START_WORKING_DAY)}
                type="datepicker"
                value={
                  selectedStartWorkingDay || null
                }
                onChange={handleStartWorkingDayChange}
                dateFormat="yyyy-MM-dd"
                placeholder={t(TRANSLATIONS.SELECT_DATE)}
                inputStyle={invalidFields.includes('startWorkingDay') ? {
                  borderColor: COLOR_CODE_NEW.PRIMARY,
                } : { marginTop: '10px' }}
                maxDate={new Date()}
                datePickerColor={COLOR_CODE_NEW.PRIMARY}
                showDatePickerError={invalidFields.includes('startWorkingDay')}
                inputHeight='45px'
                inputWidth='170px'
                inputLabelStyle={{ top: '-4px' }}
              />
              {(invalidFields.includes('startWorkingDay') && !selectedStartWorkingDay) && (
                <span className="addUser__startWorkingDay__input--error">
                  {t(TRANSLATIONS.SELECT_DATE)}
                </span>
              )}
            </div>
          </>
        )}
        {showRoleOptions && (
          <>
            <div className="addUser__divider" />
            <div className="addUser__permissionsTitle">{t(TRANSLATIONS.PERMISSIONS)}</div>
            {permissionsOptions.map(({ id, label }) => (
              <CheckBoxWithIcon
                key={id}
                onClick={() => onChangeRole(id)}
                deSelectedIcon={ImageLinks.emptyCheck}
                selectedIcon={ImageLinks.checkRedSelected}
                selected={selectedRole === id}
                label={label}
              />
            ))}
          </>
        )}
        <div className="addUser__bottom_container">
          <div className="addUser__bottom_container__buttons">
            <div>
              <BasicButton
                text={t(TRANSLATIONS.CANCEL)}
                onClick={closeModal}
                className="addUser__bottom_container__buttons__cancel"
              />

              <BasicButton
                text={t(TRANSLATIONS.ADD)}
                onClick={submitHandler}
                className="addUser__bottom_container__buttons__add"
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddUserModal;
