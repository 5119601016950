/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useContext, useEffect, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckBoxWithIcon from 'components/CheckBoxWithIcon';
import ImageLinks from 'utils/ImageLinks';
import LoadingSpinner from 'components/LoadingSpinner';
import { useGetAllAccessLocationsLazyQuery } from 'generated/graphql';
import { fromJS } from 'immutable';
import { ArrowRight } from '@mui/icons-material';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
import { grey } from '@mui/material/colors';
import DashIcon from '../../../../../../assets/icons/dash.svg';
import './Accesos.scss';
import { UserManagementContext } from '../../context';
import { getAccessLocationsIds, AccessLocation, getFormattedData } from '../utils';

const { blueFilledCheck, grayEmptyCheck } = ImageLinks;

const useStyles = makeStyles(() => ({
  root: {
    padding: '4px 4px',
    fontSize: '15px',
    fontWeight: 'bolder',
    fontFamily: 'Plus Jakarta Sans',
    color: '#2B323B',
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    padding: '4px 4px',
  },
  location: {
    paddingTop: '2px',
  },
  // group: {},
  expanded: {},
  selected: {},
  label: {},
  labelRoot: {},
  labelIcon: {
    border: '1px solid black',
  },
  labelText: {
    fontFamily: 'Jost',
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '24px',
    color: '#2B323B',
  },
}));

export default function Accesos() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [expanded, setExpanded] = useState([]);
  const [accessLocationsData, setAccessLocationsData] = useState<AccessLocation[]>([]);

  const userContext = useContext(UserManagementContext);
  const {
    user,
    originalUser,
    editingUser,
    isEditing,
    // accessLocationsIds,
    setAccessLocationsIds,
    isLoading,
    setOriginalUser,
    setEditingUser,
    setIsLoading,
  } = userContext;

  const [callAccessLocations, { data, refetch, loading }] = useGetAllAccessLocationsLazyQuery({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (refetch) {
      refetch();
    } else {
      callAccessLocations({
        variables: {
          id: user?.id,
        },
      });
    }
    // eslint-disable-next-line
  }, [refetch]);

  useEffect(() => {
    setIsLoading(loading);
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    const locationAccessData = data?.getAllAccessLocations;

    setOriginalUser(originalUser.merge(fromJS({ locationAccess: locationAccessData })));
    // eslint-disable-next-line
  }, [data?.getAllAccessLocations]);

  const handleToggle = (event: any, nodeIds: any) => {
    setExpanded(nodeIds);
  };

  useEffect(() => {
    let tempData;

    const originalAccessLocations = originalUser?.get('locationAccess')?.toJS();
    const editingAccessLocations = editingUser?.get('locationAccess')?.toJS();

    if (isEditing) {
      tempData = getFormattedData(editingAccessLocations);
    } else {
      tempData = getFormattedData(originalAccessLocations);
    }

    if (tempData) {
      setAccessLocationsIds(getAccessLocationsIds(tempData));
      getAccessLocationsIds(tempData);
      setAccessLocationsData(tempData);
    }

    // eslint-disable-next-line
  }, [isEditing, originalUser]);

  function handleLocationsUpdate(
    buIndex: number,
    zoneIndex: number,
    subZoneIndex: number,
    locationIndex: number,
  ) {
    const updatedData: AccessLocation[] = JSON.parse(JSON.stringify(accessLocationsData));
    const newValue = !updatedData[buIndex].zones[zoneIndex].subzones[subZoneIndex]
      .distributionCenters[locationIndex].hasAccess;
    updatedData[buIndex].zones[zoneIndex].subzones[subZoneIndex].distributionCenters[
      locationIndex
    ].hasAccess = newValue;
    const ids = getAccessLocationsIds(updatedData);
    setAccessLocationsData([...updatedData]);
    setAccessLocationsIds(getAccessLocationsIds(updatedData));
    setEditingUser((editingUserData: any) => editingUserData.setIn(['locationAccess', 'arrayOfAccessLocations'], ids));
  }

  const areAllBuSelected = useCallback(
    (buIndex: number) => {
      let locationsWithAccess = 0;
      let totalLocations = 0;
      const bu = accessLocationsData[buIndex];
      bu.zones.forEach(zone => {
        zone.subzones.forEach(subzone => {
          subzone.distributionCenters.forEach(center => {
            if (center.hasAccess) {
              locationsWithAccess += 1;
            }
            totalLocations += 1;
          });
        });
      });
      return locationsWithAccess === totalLocations && totalLocations !== 0;
    },
    [accessLocationsData],
  );

  const areSomeBuSelected = useCallback(
    (buIndex: number) => {
      let locationsWithAccess = 0;
      let totalLocations = 0;
      const bu = accessLocationsData[buIndex];
      bu.zones.forEach(zone => {
        zone.subzones.forEach(subzone => {
          subzone.distributionCenters.forEach(center => {
            if (center.hasAccess) {
              locationsWithAccess += 1;
            }
            totalLocations += 1;
          });
        });
      });
      return locationsWithAccess > 0 && totalLocations !== 0;
    },
    [accessLocationsData],
  );

  const areAllZoneSelected = useCallback(
    (buIndex: number, zoneIndex: number) => {
      let locationsWithAccess = 0;
      let totalLocations = 0;
      accessLocationsData[buIndex].zones[zoneIndex].subzones.forEach(subzone => {
        subzone.distributionCenters.forEach(center => {
          if (center.hasAccess) {
            locationsWithAccess += 1;
          }
          totalLocations += 1;
        });
      });
      return locationsWithAccess === totalLocations && totalLocations !== 0;
    },
    [accessLocationsData],
  );

  const areSomeZoneSelected = useCallback(
    (buIndex: number, zoneIndex: number) => {
      let locationsWithAccess = 0;
      let totalLocations = 0;
      accessLocationsData[buIndex].zones[zoneIndex].subzones.forEach(subzone => {
        subzone.distributionCenters.forEach(center => {
          if (center.hasAccess) {
            locationsWithAccess += 1;
          }
          totalLocations += 1;
        });
      });
      return locationsWithAccess > 0 && totalLocations !== 0;
    },
    [accessLocationsData],
  );

  const areAllLocationsSelected = useCallback(
    (buIndex: number, zoneIndex: number, subZoneIndex: number) => {
      let locationsWithAccess = 0;
      let totalLocations = 0;
      accessLocationsData[buIndex].zones[zoneIndex!].subzones[
        subZoneIndex
      ].distributionCenters.forEach(center => {
        if (center.hasAccess) {
          locationsWithAccess += 1;
        }
        totalLocations += 1;
      });
      return locationsWithAccess === totalLocations && totalLocations !== 0;
    },
    [accessLocationsData],
  );

  const areSomeLocationsSelected = useCallback(
    (buIndex: number, zoneIndex: number, subZoneIndex: number) => {
      const isSome = accessLocationsData[buIndex].zones[zoneIndex!].subzones[
        subZoneIndex
      ].distributionCenters.some(center => center?.hasAccess);
      return isSome;
    },
    [accessLocationsData],
  );

  const handleToggleBU = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, buIndex: number) => {
      event.stopPropagation();
      const updatedData: AccessLocation[] = JSON.parse(JSON.stringify(accessLocationsData));
      const newValue = !areAllBuSelected(buIndex);
      const updatedZones = updatedData[buIndex].zones.map(zone => ({
        ...zone,
        subzones: zone.subzones.map(subzone => ({
          ...subzone,
          distributionCenters: subzone.distributionCenters.map(center => ({
            ...center,
            hasAccess: newValue,
          })),
        })),
      }));
      updatedData[buIndex].zones = updatedZones;
      const ids = getAccessLocationsIds(updatedData);
      setAccessLocationsData(updatedData);
      setAccessLocationsIds(ids);
      setEditingUser((editingUserData: any) => editingUserData.setIn(['locationAccess', 'arrayOfAccessLocations'], ids));
    },
    // eslint-disable-next-line
    [accessLocationsData],
  );

  const handleToggleZone = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, buIndex: number, zoneIndex: number) => {
      event.stopPropagation();
      const updatedData: AccessLocation[] = JSON.parse(JSON.stringify(accessLocationsData));
      const newValue = !areAllZoneSelected(buIndex, zoneIndex);
      const updatedSubZones = updatedData[buIndex].zones[zoneIndex].subzones.map(subzone => ({
        ...subzone,
        distributionCenters: subzone.distributionCenters.map(center => ({
          ...center,
          hasAccess: newValue,
        })),
      }));
      updatedData[buIndex].zones[zoneIndex].subzones = updatedSubZones;
      const ids = getAccessLocationsIds(updatedData);
      setAccessLocationsData(updatedData);
      setAccessLocationsIds(ids);
      setEditingUser((editingUserData: any) => editingUserData.setIn(['locationAccess', 'arrayOfAccessLocations'], ids));
    },
    // eslint-disable-next-line
    [accessLocationsData],
  );

  const handleToggleSubzone = useCallback(
    (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      buIndex: number,
      zoneIndex: number,
      subZoneIndex: number,
    ) => {
      event.stopPropagation();
      const updatedData: AccessLocation[] = JSON.parse(JSON.stringify(accessLocationsData));
      const newValue = !areAllLocationsSelected(buIndex, zoneIndex!, subZoneIndex);
      const updatedCenters = updatedData[buIndex].zones[zoneIndex!].subzones[
        subZoneIndex
      ].distributionCenters.map(center => ({
        ...center,
        hasAccess: newValue,
      }));
      updatedData[buIndex].zones[zoneIndex!].subzones[
        subZoneIndex
      ].distributionCenters = updatedCenters;
      const ids = getAccessLocationsIds(updatedData);
      setAccessLocationsData(updatedData);
      setAccessLocationsIds(ids);
      setEditingUser((editingUserData: any) => editingUserData.setIn(['locationAccess', 'arrayOfAccessLocations'], ids));
    },
    // eslint-disable-next-line
    [accessLocationsData],
  );

  const renderChildrenTrees = (el: any, index: number) => {
    return (
      <TreeItem
        classes={{
          content: classes.content,
        }}
        key={`parent-key-${index}`}
        itemId={`parent-node-id${index}`}
        label={(
          <div className="d-flex align-items-center">
            {isEditing ? (
              <CheckBoxWithIcon
                onClick={event => {
                  if (isEditing) {
                    handleToggleBU(event, index);
                  }
                }}
                deSelectedIcon={grayEmptyCheck}
                selectedIcon={areAllBuSelected(index) ? blueFilledCheck : DashIcon}
                selected={areAllBuSelected(index) || areSomeBuSelected(index)}
                disabled={!isEditing}
              />
            ) : null}
            <span className="access-label">{el?.name}</span>
          </div>
        )}
      >
        {el?.zones?.map((zone: any, zIndex: number) => {
          return (
            <TreeItem
              key={`child-key-${zone?.id}`}
              itemId={`child-node-id${zone?.id}`}
              label={(
                <div className="d-flex align-items-center">
                  {isEditing ? (
                    <CheckBoxWithIcon
                      onClick={event => {
                        if (isEditing) {
                          handleToggleZone(event, index, zIndex);
                        }
                      }}
                      deSelectedIcon={grayEmptyCheck}
                      selectedIcon={
                        areAllZoneSelected(index, zIndex) ? blueFilledCheck : DashIcon
                      }
                      selected={
                        areAllZoneSelected(index, zIndex)
                        || areSomeZoneSelected(index, zIndex)
                      }
                      disabled={!isEditing}
                    />
                  ) : null}
                  <span className="access-label">{zone?.name}</span>
                </div>
              )}
              className={classes.content}
            >
              {zone?.subzones?.map((subzone: any, szIndex: number) => {
                return (
                  <TreeItem
                    key={`subzone-key-${subzone?.id}`}
                    itemId={`subzone-node-id${subzone?.id}`}
                    classes={{ content: classes.location }}
                    label={(
                      <div className="d-flex align-items-center">
                        {isEditing ? (
                          <CheckBoxWithIcon
                            onClick={event => {
                              if (isEditing) {
                                handleToggleSubzone(event, index, zIndex, szIndex);
                              }
                            }}
                            deSelectedIcon={grayEmptyCheck}
                            selectedIcon={
                              areAllLocationsSelected(
                                index, zIndex, szIndex,
                              ) ? blueFilledCheck : DashIcon
                            }
                            selected={
                              areAllLocationsSelected(index, zIndex, szIndex)
                              || areSomeLocationsSelected(index, zIndex, szIndex)
                            }
                            disabled={!isEditing}
                          />
                        ) : null}
                        <span className="access-label">{subzone?.name}</span>
                      </div>
                    )}
                    className={classes.content}
                  >
                    {subzone?.distributionCenters
                      && subzone?.distributionCenters?.length && (
                      <div className="mt-2 ml-3">
                        {subzone?.distributionCenters?.map(
                          (location: any, cIndex: number) => (
                            <CheckBoxWithIcon
                              key={location?.id}
                              onClick={() => {
                                if (isEditing) {
                                  handleLocationsUpdate(index, zIndex, szIndex, cIndex);
                                }
                              }}
                              deSelectedIcon={grayEmptyCheck}
                              selectedIcon={blueFilledCheck}
                              selected={location?.hasAccess}
                              label={location?.name}
                              disabled={!isEditing}
                              iconStyle={{ width: '20px' }}
                            />
                          ),
                        )}
                      </div>
                    )}
                  </TreeItem>
                );
              })}
            </TreeItem>
          );
        })}
      </TreeItem>
    );
  };

  const renderTree = (nodes: any) => {
    const businessUnitsSupply = Object.entries(nodes)
      .filter((el: any) => el[1]?.slug.includes('supply'))
      .map(([index, value]) => ({
        index: Number(index),
        value,
      }));

    const businessUnitsLogistics = Object.entries(nodes)
      .filter((el: any) => !el[1]?.slug.includes('supply'))
      .map(([index, value]) => ({
        index: Number(index),
        value,
      }));

    console.log('businessUnitsSupply --> ', businessUnitsSupply);
    console.log('businessUnitsLogistics --> ', businessUnitsLogistics);

    return (
      nodes && (
        <TreeItem
          itemId="main-node-id-0"
          classes={{
            root: classes.root,
            content: classes.content,
            expanded: classes.expanded,
            selected: classes.selected,
            // group: classes.group,
            label: classes.label,
          }}
          label={(
            <div className="d-flex align-items-center">
              {isEditing ? <img src={DashIcon} width="24px" alt="MAZ" className="pr-1" /> : null}
              <span className="access-label">MAZ</span>
            </div>
          )}
        >
          <span className="bu-sections">{t(TRANSLATIONS.LOGISTICS)}</span>
          {businessUnitsLogistics?.map((el: any) => {
            const originalIndex = el.index;
            const elementData = el.value;
            return renderChildrenTrees(elementData, originalIndex);
          })}
          <span className="bu-sections">Supply</span>
          {businessUnitsSupply?.map((el: any) => {
            const originalIndex = el.index;
            const elementData = el.value;
            return renderChildrenTrees(elementData, originalIndex);
          })}
        </TreeItem>
      )
    );
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <SimpleTreeView
      // expanded={expanded}
      slots={{
        collapseIcon: ArrowDropDownIcon,
        expandIcon: ArrowRight,
      }}
      disableSelection
      // onNodeToggle={handleToggle}
    >
      {renderTree(accessLocationsData)}
    </SimpleTreeView>
  );
}
