import React, { useContext, useEffect, useState } from 'react';
import { Tabs, Tab, Box, Drawer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { fromJS } from 'immutable';
import {
  useGetUserInfoLazyQuery,
  useGetAllAccessLocationsLazyQuery,
  useGetUserWorkLocationsLazyQuery,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import { UserManagementContext } from '../context';
import PermissionsTab from './PermissionsTab';
import Profile from './Profile';
import './UserManagement.scss';
import General from './General';
import Accesos from './Accesos';
import { getAccessLocationsIds, getFormattedData } from './utils';

const useStyles = makeStyles(() => ({
  indicator: {
    backgroundColor: '#EFBE3F',
    border: 'none',
  },
  root: {
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
      backgroundColor: '#EFBE3F',
      height: 2,
    },
  },
  tabRoot: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: 'normal',
    marginRight: '32px',
    '&:hover': {
      color: '#EFBE3F',
      opacity: 1,
    },
    '&.Mui-selected': {
      color: '#EFBE3F !important',
      borderBottomColor: '#EFBE3F !important',
      fontWeight: 'normal',
    },
    '&:focus': {
      color: '#EFBE3F',
    },
  },
}));

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={index} {...other}>
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const Usermanagement = (props: any) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [isAuthorisedForOWD, setIsAuthorisedForOWD] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const userContext = useContext(UserManagementContext);
  const {
    user,
    originalUser,
    editingUser,
    isEditing,
    setOriginalUser,
    setEditingUser,
    isUMDrawerVisible,
    setUMDrawerVisibility,
    setIsLoading,
    setAccessLocationsIds,
  } = userContext;

  const [callUserInfo, { data, loading }] = useGetUserInfoLazyQuery({ fetchPolicy: 'no-cache' });

  const [
    callUserWorkLocations,
    {
      data: dataUserWorkLocations,
      loading: loadingUserWorkLocations,
    },
  ] = useGetUserWorkLocationsLazyQuery({ fetchPolicy: 'no-cache' });

  const [callAccessLocations, { data: userAccessData }] = useGetAllAccessLocationsLazyQuery({
    fetchPolicy: 'no-cache',
  });

  // useEffect(() => {
  //   if (userAccessRefetch) {
  //     userAccessRefetch({
  //       id: user?.id,
  //     });
  //   } else {
  //     callAccessLocations({
  //       variables: {
  //         id: user?.id,
  //       },
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [userAccessRefetch]);

  useEffect(() => {
    const locationAccessData = userAccessData?.getAllAccessLocations;

    setOriginalUser(originalUser.merge(fromJS({ locationAccess: locationAccessData })));
    setEditingUser(editingUser.merge(fromJS({ locationAccess: locationAccessData })));
    // eslint-disable-next-line
  }, [userAccessData?.getAllAccessLocations, isEditing]);

  useEffect(() => {
    if (isUMDrawerVisible && user?.id) {
      // if (refetch || refetchUserWorkLocations) {
      //   if (refetch) {
      //     refetch({
      //       id: user?.id,
      //     });
      //   }
      //   if (refetchUserWorkLocations) {
      //     refetchUserWorkLocations({
      //       userId: user?.id,
      //     });
      //   }
      // } else {
      callUserInfo({
        variables: {
          id: user?.id,
        },
      });
      callUserWorkLocations({
        variables: {
          userId: user?.id,
        },
      });
      callAccessLocations({
        variables: {
          id: user?.id,
        },
      });
    }
    // }
    // eslint-disable-next-line
  }, [isUMDrawerVisible]);

  useEffect(() => {
    let tempData;

    const originalAccessLocations = originalUser?.get('locationAccess')?.toJS();
    const editingAccessLocations = editingUser?.get('locationAccess')?.toJS();

    if (isEditing) {
      tempData = getFormattedData(editingAccessLocations);
    } else {
      tempData = getFormattedData(originalAccessLocations);
    }

    if (tempData) {
      setAccessLocationsIds(getAccessLocationsIds(tempData));
    }

    // eslint-disable-next-line
  }, [isEditing, originalUser]);

  useEffect(() => {
    const tempObj = {
      profile: data?.getUserInfo,
      general: {
        position: data?.getUserInfo?.position,
        employeeNumber: data?.getUserInfo?.employeeNumber,
        email: data?.getUserInfo?.email,
        pin: data?.getUserInfo?.pin,
        distributionCenter: {
          id: data?.getUserInfo?.distributionCenter?.id,
          name: data?.getUserInfo?.distributionCenter?.name,
          bu: data?.getUserInfo?.distributionCenter?.bu,
          type: data?.getUserInfo?.distributionCenter?.type,
        },
        userWorkLocation: dataUserWorkLocations?.getUserWorkLocations[0] ?? null,
        doctorStatus: data?.getUserInfo?.doctorStatus,
        startWorkingDay: data?.getUserInfo?.startWorkingDay,
      },
      permissions: {
        roleId: data?.getUserInfo?.userRole?.id,
        roleSlug: data?.getUserInfo?.userRole?.slug,
        roleCategory: data?.getUserInfo?.userRole?.category,
        type: {
          type: data?.getUserInfo?.type,
        },
      },
    };

    setIsAuthorisedForOWD(data?.getUserInfo?.info?.isAuthorisedForOWD || false);

    setOriginalUser(fromJS(tempObj));
    setEditingUser(fromJS(tempObj));
    // eslint-disable-next-line
  }, [data, loading, isUMDrawerVisible, dataUserWorkLocations, loadingUserWorkLocations]);

  useEffect(() => {
    setIsAuthorisedForOWD(data?.getUserInfo?.info?.isAuthorisedForOWD || false);
  }, [data, isEditing]);

  useEffect(() => {
    setIsLoading(loading);
  // eslint-disable-next-line
  }, [loading]);

  const tabsData = [
    {
      id: '1',
      label: t(TRANSLATIONS.GENERAL),
    },
    {
      id: '2',
      label: t(TRANSLATIONS.PERMISSIONS),
    },
    {
      id: '3',
      label: t(TRANSLATIONS.ACCESS),
    },
  ];

  return (
    <Drawer
      anchor="right"
      open={isUMDrawerVisible}
      onClose={() => {
        setUMDrawerVisibility(false);
        setTabIndex(0);
      }}
    >
      <div>
        {/* @ts-ignore */}
        <Profile
          getUserInfo={props.getUserInfo}
          isAuthorisedForOWD={isAuthorisedForOWD}
          showDeletedUsers={props.showDeletedUsers}
          isReactiveUsers={props.isReactiveUsers}
        />
        <div className="pt-2">
          <Tabs
            classes={{ indicator: classes.indicator, root: classes.root }}
            value={tabIndex}
            onChange={(event: any, newValue: number) => setTabIndex(newValue)}
          >
            {tabsData?.map(tab => {
              return (
                <Tab
                  disableRipple
                  key={tab.id}
                  classes={{ root: classes.tabRoot }}
                  id={tab.id}
                  label={tab.label}
                />
              );
            })}
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            <General />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <PermissionsTab {...{ isAuthorisedForOWD,
              setIsAuthorisedForOWD,
              isDeletedUser: props.showDeletedUsers }}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>
            <Accesos />
          </TabPanel>
        </div>
      </div>
    </Drawer>
  );
};

export default Usermanagement;
