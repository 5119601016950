import CheckBoxWithIcon from 'components/CheckBoxWithIcon';
import React from 'react';
import { capitalizeFirst } from 'utils/stringHelpers';
import './UserInfo.scss';
import ImageLinks from 'utils/ImageLinks';

const { emptyRadioUnchecked, yellowRadioChecked } = ImageLinks;

interface RoutinesByPillars {
  selected: boolean;
  routines: {
    slug: string;
    name: string;
    granted: boolean;
    editable: boolean;
  }[];
}

interface RoutinesSelectorProps {
  routinesByPillars: {
    [key: string]: RoutinesByPillars;
  };
}

function getCountOfGrantedRoutines(routinesByPillars: RoutinesByPillars) {
  return routinesByPillars.routines.filter(routine => !!routine.granted).length;
}

function getCountOfTotalRoutines(routinesByPillars: RoutinesByPillars) {
  return routinesByPillars.routines.length;
}

const RoutinesViewer : React.FC<any> = (props: RoutinesSelectorProps) => {
  const { routinesByPillars: routinesGroupedByPillar } = props;

  return (
    <>
      <div className="row select_all_container">
        <div className="routineList">
          {Object.keys(routinesGroupedByPillar).map((pillar: string) => {
            const { routines } = routinesGroupedByPillar[pillar];
            if (routinesGroupedByPillar[pillar].selected) {
              const grantedRoutines = routines.filter(r => r.granted);
              if (grantedRoutines.length < 1) {
                return null;
              }
              return (
                <div className="routineContainer" key={pillar}>
                  <h4>
                    {capitalizeFirst(pillar)} (
                    {getCountOfGrantedRoutines(routinesGroupedByPillar[pillar])} /{' '}
                    {getCountOfTotalRoutines(routinesGroupedByPillar[pillar])})
                  </h4>
                  {grantedRoutines.map(routine => (
                    <CheckBoxWithIcon
                      deSelectedIcon={emptyRadioUnchecked}
                      selectedIcon={yellowRadioChecked}
                      selected={routine.granted}
                      label={routine.name}
                      key={routine.slug}
                    />
                  ))}
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    </>
  );
};

export default RoutinesViewer;
