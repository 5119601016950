import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import Modal from 'components/Modal';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import { useSelector } from 'react-redux';
import BasicButton from 'components/Button/BasicButton';
import { selectLocationsToAccess, selectSelectedLocation } from 'store/selectors/globalFilters.selectors';
import './DownloadModal.scss';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import axios from 'axios';

export type Props = {
  closeModal: () => void;
};

export enum DropDowns {
  month = 'month',
  year = 'year',
}

const { CancelToken } = axios;

const DownloadModal = ({ closeModal } : Props) => {
  const { t } = useTranslation();

  const cancelSource = useRef<any>(null);
  const isRequestCancelledManually = useRef<any>(false);

  const monthArr = [
    t(TRANSLATIONS.JANUARY),
    t(TRANSLATIONS.FEBRUARY),
    t(TRANSLATIONS.MARCH),
    t(TRANSLATIONS.APRIL),
    t(TRANSLATIONS.MAY),
    t(TRANSLATIONS.JUNE),
    t(TRANSLATIONS.JULY),
    t(TRANSLATIONS.AUGUST),
    t(TRANSLATIONS.SEPTEMBER),
    t(TRANSLATIONS.OCTOBER),
    t(TRANSLATIONS.NOVEMBER),
    t(TRANSLATIONS.DECEMBER),
  ];

  const monthOptions: any = monthArr.map((monthSlug, monthIndex) => ({
    slug: monthSlug,
    label: monthSlug.substring(0, 1).toUpperCase() + monthSlug.substring(1),
    month: monthSlug,
    monthNumber: monthIndex + 1,
  }));

  const getLatestYearsAndCurrentYear = () => {
    const localDate: Date = new Date();
    const baseYear: number = 2021;
    const currentYear: number = localDate.getUTCFullYear();
    const years: any[] = [];

    for (let i = baseYear; i <= currentYear; i++) {
      const currentYearToString: string = i.toString();

      years.push({
        label: currentYearToString,
        slug: currentYearToString,
        year: i,
      });
    }

    return years.reverse();
  };

  const yearOptions = getLatestYearsAndCurrentYear();

  const getCurrentMonth = () => {
    const localDate: Date = new Date();
    const monthIndexToGet: number = localDate.getUTCMonth();

    return monthOptions[monthIndexToGet];
  };

  const getCurrentYear = () => {
    const localDate: Date = new Date();
    const currentYear: number = localDate.getUTCFullYear();
    const yearDataToReturn = yearOptions.find(year => Number(year.label) === currentYear);
    return yearDataToReturn ?? yearOptions[0];
  };

  const [isDropDownOpen, setIsDropDownOpen] = useState<DropDowns | undefined>(undefined);
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
  const [selectedYear, setSelectedYear] = useState(getCurrentYear());
  const [downloadError, setDownloadError] = useState<boolean>(false);
  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
  const [sentViaEmail, setSentViaEmail] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const isDCSelected = useSelector(selectSelectedLocation);
  const locations = useSelector(selectLocationsToAccess);

  const validMonthOptions: any = useMemo(() => {
    const localDate: Date = new Date();
    const currentYear: number = localDate.getUTCFullYear();
    if (selectedYear.year === currentYear) {
      const currentMonthIndex = localDate.getUTCMonth();
      const possibleMonths = monthOptions.slice(0, currentMonthIndex + 1);
      if (isDCSelected?.locationSlug) {
        return [
          { slug: t(TRANSLATIONS.ALL), label: t(TRANSLATIONS.ALL), allMonths: true },
          ...possibleMonths,
        ];
      }
      return possibleMonths;
    }
    if (isDCSelected?.locationSlug) {
      return [
        { slug: t(TRANSLATIONS.ALL), label: t(TRANSLATIONS.ALL), allMonths: true },
        ...monthOptions,
      ];
    }
    return monthOptions;
    // eslint-disable-next-line
  }, [selectedYear, isDCSelected]);

  const handleDropYearChange = ({ key }: { key: any }) => {
    const selectedDropDownYear = yearOptions.find(item => item.slug === key);
    setSelectedYear(selectedDropDownYear || yearOptions[0]);
    setIsDropDownOpen(undefined);
  };

  const dropDownOpenCloseHandler = (opened: boolean, dropDownType: DropDowns) => {
    return opened ? setIsDropDownOpen(dropDownType) : setIsDropDownOpen(undefined);
  };

  const handleDropMonthChange = ({ key }: { key: any }) => {
    const selectedDropDownMonth = validMonthOptions.find(
      (item: { slug: any; }) => item.slug === key,
    );
    setSelectedMonth(selectedDropDownMonth || validMonthOptions[0]);
    setIsDropDownOpen(undefined);
  };

  const onPressCancelRequest = () => {
    setSentViaEmail(false);
    setDownloadError(false);
    setShowProgressBar(false);
    setProgressPercentage(0);
    if (cancelSource?.current) {
      isRequestCancelledManually.current = true;
      cancelSource.current.cancel();
    }
    if (closeModal) {
      closeModal();
    }
  };

  const onPressClose = () => {
    setSentViaEmail(false);
    setDownloadError(false);
    setShowProgressBar(false);
    setProgressPercentage(0);
    if (closeModal) {
      closeModal();
    }
  };

  const downloadExcel = useCallback(
    async () => {
      cancelSource.current = CancelToken.source();
      try {
        setSentViaEmail(false);
        setDownloadError(false);
        setShowProgressBar(true);
        const url = '/api/v1/dpo-academy/download-excel';
        const res = await axios({
          url,
          method: 'GET',
          onDownloadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgressPercentage(percentCompleted);
          },
          params: {
            month: selectedMonth?.monthNumber,
            year: selectedYear?.slug,
            locations: locations?.toString(),
          },
          cancelToken: cancelSource.current.token,
        });
        if (res.data?.sentViaEmail) {
          return setSentViaEmail(true);
        }
        const downloadUrl = res.data;
        const a = document.createElement('a');
        a.href = downloadUrl;
        closeModal();
        setShowProgressBar(false);
        setProgressPercentage(0);
        a.click();
      } catch (error) {
        if (!isRequestCancelledManually.current) {
          setDownloadError(true);
          setProgressPercentage(0);
          isRequestCancelledManually.current = false;
        }
      }
    // eslint-disable-next-line
    }, [locations, selectedMonth, selectedYear],
  );

  useEffect(() => {
    const currentYear: number = (new Date()).getUTCFullYear();
    if (selectedYear.year === currentYear) {
      const currentMonthIndex = (new Date()).getMonth();
      if (selectedMonth.monthNumber > currentMonthIndex + 1) {
        handleDropMonthChange({ key: monthArr[currentMonthIndex] });
      }
    }

  // eslint-disable-next-line
  }, [validMonthOptions]);

  return (
    <Modal
      modalContainerStyles={{ width: '70vw',
        left: '20%',
        maxHeight: '90vh',
        minHeight: '20vh',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)' }}
    >
      <div className="downloadMoal">
        <p className="downloadMoal__title">{downloadError ? `${t(TRANSLATIONS.SOMETHING_WENT_WRONG)}...` : t(TRANSLATIONS.DOWNLOAD_DATA)}</p>
        {showProgressBar ? (
          <>
            <p className="downloadMoal__downloading_title">{downloadError ? t(TRANSLATIONS.DOWNLOAD_FAIL_RETRY_THE_DOWNLOAD_PLEASE) : sentViaEmail ? t(TRANSLATIONS.THE_DATA_IS_TAKING_A_LONG_TIME_TO_DOWNLOAD_A_DOWNLOAD_LINK_WILL_BE_SENT_TO_YOUR_EMAIL_TO_CONTINUE) : t(TRANSLATIONS.DOWNLOADING_DATA)}</p>
            {!downloadError && !sentViaEmail && (
              <div className="downloadMoal__progress_bar">
                <p className="downloadMoal__progress_bar__title">{progressPercentage}%</p>
                <ProgressBar bgcolor="#4472C4" height={16} progress={progressPercentage} />
              </div>
            )}
          </>
        ) : (
          <>
            <p className="downloadMoal__description">{t(TRANSLATIONS.THE_DATA_FOR_THE_LOCATIONS_THAT_ARE_FILTERED_IN_THE_TOP_FILTER_BAR_WILL_BE_DOWNLOADED)}</p>
            <div className="downloadMoal__header">
              <Header showLogo={false} showSettings={false} />
            </div>
            <div className="downloadMoal__yearFilter">
              <div>
                <p className="downloadMoal__yearFilter__yearTitle">{t(TRANSLATIONS.YEAR)}</p>
                <ChartDropdown
                  onChange={handleDropYearChange}
                  selectedDropDownItem={selectedYear}
                  dropDownItems={yearOptions}
                  dropDownOpen={isDropDownOpen === DropDowns.year}
                  setDropDownOpen={
                (opened: boolean) => dropDownOpenCloseHandler(opened, DropDowns.year)
              }
                  labelClassName="label-default"
                  dropdownArrowColor="#2B323B"
                />
              </div>
              <div>
                <p className="downloadMoal__yearFilter__monthTitle">{t(TRANSLATIONS.MONTH)}</p>
                <ChartDropdown
                  onChange={handleDropMonthChange}
                  selectedDropDownItem={selectedMonth}
                  dropDownItems={validMonthOptions}
                  dropDownOpen={isDropDownOpen === DropDowns.month}
                  setDropDownOpen={
                (opened: boolean) => dropDownOpenCloseHandler(opened, DropDowns.month)
              }
                  labelClassName="label-default"
                  dropdownArrowColor="#2B323B"
                />
              </div>
            </div>
          </>
        )}
        {!sentViaEmail ? (
          <div className="downloadMoal__button_container">
            {downloadError ? (
              <BasicButton text={t(TRANSLATIONS.RETRY_AGAIN)} className="downloadMoal__button_container__download_button" onClick={downloadExcel} />
            ) : (
              <BasicButton disabled={showProgressBar} text={t(TRANSLATIONS.DOWNLOAD)} className="downloadMoal__button_container__download_button" onClick={downloadExcel} />
            )}
            <BasicButton text={t(TRANSLATIONS.CANCEL)} className="downloadMoal__button_container__cancel_buttons" onClick={showProgressBar ? onPressCancelRequest : onPressClose} />
          </div>
        ) : (
          <div className="downloadMoal__continue_button_container">
            <BasicButton text={t(TRANSLATIONS.CONTINUE)} className="downloadMoal__continue_button_container__continue_button" onClick={closeModal} />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DownloadModal;
