import React from 'react';

type Props = {
  label?: string;
  selected?: boolean;
  selectedIcon?: string;
  deSelectedIcon?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
  required?: boolean;
  iconStyle?: {};
};

const CheckBoxWithIcon = (props: Props) => {
  const {
    selected,
    selectedIcon,
    deSelectedIcon,
    label,
    onClick,
    disabled,
    required,
    iconStyle,
  } = props;

  const handleClick = (event: any) => {
    if (!disabled && onClick) {
      onClick(event);
    }
  };

  return (
    <div
      onClick={handleClick}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: disabled ? 'not-allowed' : 'pointer',
        marginBottom: '5px',
      }}
      role="presentation"
    >
      <img style={{ ...iconStyle }} src={selected ? selectedIcon : deSelectedIcon} alt="Checkbox" />
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '8px',
        }}
      >
        {required && <p style={{ color: '#E92B36' }}>*</p>}
        {label}
      </span>
    </div>
  );
};

export default CheckBoxWithIcon;
