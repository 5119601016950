import React, { useState, useRef } from 'react';
import './DPOAcademy.scss';
import { useTranslation } from 'react-i18next';
import req from 'utils/request';
import { TRANSLATIONS } from 'types/enums';
import { toast } from 'react-toastify';
import BasicButton from 'components/Button/BasicButton';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/selectors/user.selectors';
import DownloadModal from 'components/DpoAcademy/DownloadModal/DownloadModal';
// import emptyImage from 'assets/icons/maintenance.svg';
import ChartTabs from 'components/Tabs/ChartTabs';
import Iframe from 'components/Iframe';
import { selectUserAccesses } from 'store/selectors/globalFilters.selectors';
import ButtonWithTooltip from 'components/Button/ButtonWithTooltip';

const validFileExtensions = ['.csv', '.xlsx'];
const Validate = (files: any) => {
  for (let i = 0; i < files.length; i++) {
    const oInput = files[i];

    const sFileName = oInput.name;
    if (sFileName.length > 0) {
      let blnValid = false;
      for (let j = 0; j < validFileExtensions.length; j++) {
        const sCurExtension = validFileExtensions[j];
        console.log({ sCurExtension, sFileName });
        if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length)
          .toLowerCase() === sCurExtension.toLowerCase()) {
          blnValid = true;
          break;
        }
      }

      if (!blnValid) {
        alert(`Sorry, ${sFileName} is invalid, allowed extensions are: ${validFileExtensions.join(', ')}`);
        return false;
      }
    }
  }

  return true;
};

const DPOAcademyContainer = () => {
  const { t } = useTranslation();

  const userHasAccesses = useSelector(selectUserAccesses)?.length > 0;
  const tooltipMessage = t(TRANSLATIONS.YOU_DONT_HAVE_ACCESS_TO_LOCATION_PLEASE_CONTACT_SUPERUSER);

  const tabs = [
    {
      title: t(TRANSLATIONS.RESULTS),
      value: '',
      pageName: 'ReportSection70316f689de55ac87a29',
      type: TRANSLATIONS.RESULTS,
      index: 0,
    },
    {
      title: t(TRANSLATIONS.ADHERENCE),
      value: '',
      pageName: 'ReportSectionebe39bbcd42bf093081e',
      type: TRANSLATIONS.ADHERENCE,
      index: 1,
    },

  ];

  const checklistRef = useRef<HTMLInputElement>(null);
  const excelRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const userDetails = useSelector(selectUser);

  const fileUploadData = {
    excel: {
      ref: excelRef,
      url: '/api/v1/dpo-academy/program-excel',
    },
    checklist: {
      ref: checklistRef,
      url: '/api/v1/dpo-academy/checklist-excel',
    },
  };

  const handleUploadButton = (fileRef:any) => {
    if (fileRef.current !== null) {
      fileRef.current.click();
    }
  };

  const handleAttachmentsUpload = async (e: any, fileType: any) => {
    const { files } = e.target;
    if (files?.length && Validate(files)) {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      const data = new FormData();

      data.set('file', files[0]);

      try {
        setLoading(true);
        await req.post(fileType.url, data, config);
        setLoading(false);
        toast.success('Uploaded Successfully!');
      } catch (err) {
        setLoading(false);
        toast.error('Error while uploading');
      } finally {
        if (fileType.ref.current !== null) {
          setLoading(false);
          fileType.ref.current.value = '';
        }
      }
    }
  };

  const onClickViewMaterial = () => {
    window.open(
      'https://anheuserbuschinbev-my.sharepoint.com/:f:/g/personal/32209283_gmodelo_com_mx/Ehc97sbX4XBEpQWw2u7sfNABrJBFNVGkQ4o-697MO27imA?e=kZ4D24',
      '_blank',
      'noreferrer',
    );
  };

  const onClickDownload = () => {
    setShowDownloadModal(true);
  };

  const closeModal = () => {
    setShowDownloadModal(false);
  };

  // const renderEmptyPage = () => {
  //   return (
  //     <div className="empty_page">
  //       <img className="center-fit" src={emptyImage} alt="No data" />
  //     </div>
  //   );
  // };

  return (
    <section className="root_container">
      {showDownloadModal && (
        <DownloadModal closeModal={closeModal} />
      )}
      <div className="root_header">
        <p className="title">{t(TRANSLATIONS.DPO_ACADEMY)}</p>
        <div className="upload_container">
          <ButtonWithTooltip
            text={t(TRANSLATIONS.DOWNLOAD_DATA)}
            className="download_button"
            onClick={onClickDownload}
            disabled={!userHasAccesses}
            tooltipMessage={tooltipMessage}
          />
          <BasicButton
            text={t(TRANSLATIONS.DPO_ACADEMY_MATERIAL)}
            className="material_button"
            onClick={onClickViewMaterial}
          />
          {userDetails?.get('email').includes('arvolution') && (
          <>
            <div className="upload_button">
              <BasicButton
                text="Upload Excel"
                className="upload_button_filled"
                onClick={() => handleUploadButton(excelRef)}
                disabled={loading}
              />
              <input
                ref={excelRef}
                className="hidden"
                type="file"
                onChange={e => handleAttachmentsUpload(e, fileUploadData.excel)}
                name="file"
                multiple
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
            </div>
            <div className="upload_button">
              <BasicButton
                text="Upload Checklist"
                className="upload_button_filled"
                onClick={() => handleUploadButton(checklistRef)}
                disabled={loading}
              />
              <input
                ref={checklistRef}
                className="hidden"
                type="file"
                onChange={e => handleAttachmentsUpload(e, fileUploadData.checklist)}
                name="file"
                multiple
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
            </div>
          </>
          )}
        </div>
      </div>

      {/* {renderEmptyPage()} */}

      <div className="checklist_section__body">
        <ChartTabs
          tabsItems={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab as any}
          selectedTabStyle="selected-tab"
          customStyle="tabs-container"
        />
        <Iframe
          iframe="https://app.powerbi.com/view?r=eyJrIjoiNDAzNWFjZWQtMDg3Zi00YzAxLWEyMzAtYWJhNWNjZjY4N2UxIiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
          iframeTitle="DPO Academy"
          pageName={selectedTab.pageName}
        />
      </div>
    </section>
  );
};

export default DPOAcademyContainer;
