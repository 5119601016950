/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageLinks from 'utils/ImageLinks';
import Modal from 'components/Modal';
import { COLOR_CODE_NEW, TRANSLATIONS, UserTypes } from 'types/enums';
import BasicButton from 'components/Button/BasicButton';
import LoadingSpinner from 'components/LoadingSpinner';
import request from 'utils/request';
import { toast } from 'react-toastify';
import LabelledInputWithImage from 'components/Input/LabelledInputWithImage/LabelledInputWithImage';
import { userCanAddNewUserByEmployeeNumber, userCanAddNewUserPin } from 'utils/shared/canUserAccessFeature';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/selectors/user.selectors';
import { useGetAllLocationsLazyQuery } from 'generated/graphql';
import CheckBoxWithIcon from 'components/CheckBoxWithIcon';
import blackLocation from 'assets/icons/black-location.svg';
import userIcon from 'assets/icons/user-light.svg';
import './CreateUserModal.scss';

type UserInfoType = {
  position: string;
  location: string;
  name: string;
};

type Props = {
  onAdd: (data: UserInfoType) => void,
  closeModal: () => void;
  setShowPinModal: React.Dispatch<React.SetStateAction<boolean>>
  showPinModal: boolean,
  setSelectedLocation: React.Dispatch<React.SetStateAction<{
    text: string;
    value: string;
    type: string;
  }>>
};

const CreateUserModal = (props: Props) => {
  const { t } = useTranslation();
  const { closeModal, onAdd, setShowPinModal, showPinModal, setSelectedLocation } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [positionsForBu, setPositionsForBu] = useState<any>([]);
  const [invalidFields, setInvalidFields] = useState([] as string[]);
  const [locations, setLocations] = useState<any>([]);
  const [userInfo, setUserInfo] = useState<UserInfoType>({ position: '', location: '', name: '' });
  const [selectedRole, setSelectedRole] = useState<any>(null);
  const [selectedOptions, setSelectedOptions] = useState<any>(null);
  const [showRoleOptions, setShowRoleOptions] = useState<boolean>(false);
  const loggedInUser = useSelector(selectUser);
  const { hasUserManagementAccess } = loggedInUser.toJS();
  const [employeeNumber, setEmployeeNumber] = useState('');
  const [isSharpAvailable, setIsSharpAvailable] = useState(false);
  const [isLoadingSharpAvailability, setIsLoadingSharpAvailability] = useState(false);
  const [message, setMessage] = useState('');
  const [icon, setIcon] = useState('');
  const [showStartWorkingDayOption, setShowStartWorkingDayOption] = useState<boolean>(false);
  const [selectedStartWorkingDay, setSelectedStartWorkingDay] = useState<any>(
    null,
  );
  const [pinSelected, setPinSelected] = useState(false);

  function compareFn(a: any, b: any) {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  }

  const permissionsOptions = [
    {
      id: 31,
      label: `${t(TRANSLATIONS.CERTIFICATES)}`,
      type: UserTypes.CertificatesOnly,
    },
    {
      id: 32,
      label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)} ${t(TRANSLATIONS.INCIDENTS)}, ${t(TRANSLATIONS.ACTION_LOGS)}`,
      type: UserTypes.ReporterOnlyDPO,
    },
  ];

  const createByPinOrSharpOptions = [
    {
      id: 1,
      label: `${t(TRANSLATIONS.CREATE_BY_PIN)}`,
    },
    {
      id: 2,
      label: `${t(TRANSLATIONS.CREATE_BY_SHARP)}`,
    },
  ];

  const [callGetAllLocations] = useGetAllLocationsLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (res: any) => {
      const locationArray = res.getLocations
        ?.map((loc: any) => ({ text: loc?.name, value: loc?.slug, type: loc?.type }));
      locationArray.sort(compareFn);
      setLocations(locationArray);
      setLoading(false);
    },
    onError: () => {
      toast.error(t(TRANSLATIONS.SOMETHING_WENT_WRONG_TRY_LATER));
      setLoading(false);
      closeModal();
    },
  });

  useEffect(() => {
    setLoading(true);
    callGetAllLocations();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkEmptyFields = useMemo(() => {
    if (!userInfo.name || !userInfo.position || !userInfo.location
    || employeeNumber?.length !== 8 || !isSharpAvailable
    || (!selectedStartWorkingDay)) {
      return true;
    }
    return false;
  }, [userInfo, employeeNumber, selectedStartWorkingDay,
    isSharpAvailable]);

  const submitHandler = async () => {
    setInvalidFields([]);
    const invalidFieldsTemp = [];
    if (userInfo.name.trim().length < 3) {
      invalidFieldsTemp.push('firstName');
    }
    if (!userInfo.position) {
      invalidFieldsTemp.push('position');
    }
    if (!userInfo.location) {
      invalidFieldsTemp.push('location');
    }
    if (employeeNumber?.length !== 8) {
      invalidFieldsTemp.push('SAP');
    }
    if ( showStartWorkingDayOption && !!isNaN(selectedStartWorkingDay?.getTime())) {
      invalidFieldsTemp.push('startWorkingDay');
    }
    if (invalidFieldsTemp.length > 0) {
      setInvalidFields(invalidFieldsTemp);
      return;
    }

    setLoading(true);
    try {
      const data = await request.post(`/api/v1/distribution-centers/${userInfo.location}/users`, {
        name: userInfo.name,
        position: userInfo.position === t(TRANSLATIONS.WITHOUT_POSITION) ? '' : userInfo.position,
        type: UserTypes.CertificatesOnly,
        createUserAccessEntry: true,
        roleId: selectedRole ?? 31,
        startWorkingDay: selectedStartWorkingDay
          ? new Date(selectedStartWorkingDay)
          : null,
        employeeNumber,
      });
      if (data) {
        const locationName = locations.find((loc: any) => loc.value === userInfo.location);
        const createdUserInfo = {
          position: userInfo.position,
          location: locationName?.text,
          name: userInfo.name,
          pin: data?.user?.pin,
          shortSlug: data?.user?.shortSlug,
          employeeNumber,
        };
        setLoading(false);
        if (onAdd) {
          onAdd(createdUserInfo);
        }
      } else {
        toast.error(TRANSLATIONS.SOMETHING_WENT_WRONG_TRY_LATER);
        setLoading(false);
        closeModal();
      }
    } catch (e) {
      toast.error(TRANSLATIONS.SOMETHING_WENT_WRONG_TRY_LATER);
      console.error(e);
      setLoading(false);
      closeModal();
    }
  };

  const onChangeLocation = async (e: any) => {
    const selectedLocation = locations?.find((loc: any) => loc.value === e.target.value);
    setSelectedLocation(selectedLocation);
    if (selectedLocation?.type === 'DISTRIBUTION_CENTER') {
      setShowRoleOptions(true);
      setShowStartWorkingDayOption(true);
    } else {
      setSelectedRole(null);
      setSelectedStartWorkingDay(null);
      setShowRoleOptions(false);
      setShowStartWorkingDayOption(false);
    }
    setLoading(true);
    setUserInfo({
      ...userInfo,
      position: '',
      location: e.target.value,
    });
    try {
      const response = await request.get(`/api/v1/positions-by-bu?cdSlug=${e.target.value}`);
      const positions = response?.positions?.map(({ position }: { position: string }) => (
        { text: position, value: position }),
      );
      if (positions.length === 0) {
        positions.push(
          { text: t(TRANSLATIONS.WITHOUT_POSITION), value: t(TRANSLATIONS.WITHOUT_POSITION) },
        );
      }
      positions.sort(compareFn);
      setPositionsForBu(positions);
      setLoading(false);
    } catch (err) {
      console.error(err);
      toast.error(t(TRANSLATIONS.SOMETHING_WENT_WRONG_TRY_LATER));
      setLoading(false);
      closeModal();
    }
  };

  const onChangeFirstName = (e: any) => {
    setUserInfo({
      ...userInfo,
      name: e.target.value,
    });
  };

  const checkSharpAvailability = async (e: any) => {
    setIsLoadingSharpAvailability(true);
    try {
      const response = await request.get(`/api/v1/user-sharp-available/${e.target.value}`);
      setIsSharpAvailable(!response.userExists);
      setLoading(false);
    } catch (error) {
      console.error('Error checking SAP availability:', error);
      toast.error(t(TRANSLATIONS.SOMETHING_WENT_WRONG_TRY_LATER));
      setLoading(false);
    } finally {
      setIsLoadingSharpAvailability(false);
    }
  };

  const handleChange = (e: any) => {
    setEmployeeNumber(e.target.value);
    setInvalidFields([]);

    if (e.target.value.length === 8) {
      checkSharpAvailability(e);
    } else {
      setMessage('');
      setIcon('');
      setIsSharpAvailable(false);
    }
  };

  const onChangePosition = (e: any) => {
    setUserInfo({
      ...userInfo,
      position: e.target.value,
    });
  };

  useEffect(() => {
    const isNumeric = /^[0-9]+$/;
    if (employeeNumber.length === 0 || isLoadingSharpAvailability) {
      setMessage('');
      setIcon('');
    } else if (!isNumeric.test(employeeNumber)) {
      setMessage(t(TRANSLATIONS.SAP_SHOULD_ONLY_CONTAIN_NUMBERS));
      setIcon('error');
    } else if (employeeNumber.length === 8) {
      if (isSharpAvailable) {
        setMessage(t(TRANSLATIONS.SAP_SHARP_AVAILABLE));
        setIcon('success');
      } else {
        setMessage(t(TRANSLATIONS.SAP_SHARP_ALREADY_REGISTERED));
        setIcon('error');
      }
    } else {
      setMessage(t(TRANSLATIONS.SAP_MUST_CONTAIN_8_DIGITS));
      setIcon('error');
    }
  }, [employeeNumber, t, isSharpAvailable, isLoadingSharpAvailability]);

  const handleStartWorkingDayChange = (value: any) => {
    if (value) {
      const formattedDate = new Date(value?.getFullYear(), value?.getMonth(), value?.getDate());
      setSelectedStartWorkingDay(formattedDate);
    }
  };

  const onChangeRole = (roleId: any) => {
    setSelectedRole(roleId);
  };

  useEffect(() => {
    if (userInfo.location && pinSelected) {
      setShowPinModal(true);
    }
  }, [userInfo.location, pinSelected, setShowPinModal]);

  const onChangeOptions = (roleId: any, label?: string) => {
    setSelectedOptions(roleId);
    if (label === t(TRANSLATIONS.CREATE_BY_PIN)) {
      setPinSelected(true);
    } else if (label === t(TRANSLATIONS.CREATE_BY_SHARP)) {
      setShowPinModal(false);
      setPinSelected(false);
    }
  };

  const userCreationOptions = useMemo(() => {
    if (hasUserManagementAccess) {
      return createByPinOrSharpOptions;
    }
    const filteredOptions = [];
    if (userCanAddNewUserPin(loggedInUser?.get('features'))) {
      filteredOptions.push(createByPinOrSharpOptions[0]);
    }
    if (userCanAddNewUserByEmployeeNumber(loggedInUser?.get('features'))) {
      setShowPinModal(false);
      filteredOptions.push(createByPinOrSharpOptions[1]);
    }
    return filteredOptions;
  }, [createByPinOrSharpOptions, loggedInUser, hasUserManagementAccess, setShowPinModal]);

  if (loading) {
    return (
      <Modal modalContainerStyles={{
        width: '60vw',
        left: '20%',
        maxHeight: '80vh',
        minHeight: '30vh',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
      }}
      >
        <LoadingSpinner />
      </Modal>
    );
  }

  return (
    <div className="createUser">
      <Modal
        modalContainerStyles={{ width: '60vw',
          left: '20%',
          minHeight: '20vh',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)' }}
        onOverlayClick={closeModal}
      >
        <div className="createUser__header">
          <div className="createUser__header__userNameAndIcon">
            <span>{t(TRANSLATIONS.CREATE_USER)}</span>
          </div>
          <div className="createUser__header__userIcon">
            <img
              src={ImageLinks.userIcon}
              alt="UserPicture"
            />
          </div>
        </div>
        <h3>{t(TRANSLATIONS.USER_INFORMATION)}</h3>
        <div className="createUser__labelInputContainer">
          <LabelledInputWithImage
            inputStyle={invalidFields.includes('location') ? {
              borderColor: COLOR_CODE_NEW.PRIMARY,
              width: '320px',
            } : { width: '320px', border: '1px solid #DEDEDE', padding: '12px', fontWeight: 200, borderRadius: '5px' }}
            onChange={e => onChangeLocation(e)}
            placeholder={t(TRANSLATIONS.LOCATION_SELECT)}
            label={t(TRANSLATIONS.LOCATION)}
            icon={blackLocation}
            value={userInfo.location}
            dropdownOptions={locations || []}
            type="dropdown"
            labelStyle={{ width: '220px' }}
            errorText={t(TRANSLATIONS.LOCATION_SELECT)}
            showError={invalidFields.includes('location') && !userInfo.location}
          />
        </div>
        <div className="createUser__radioButtons">
          <div style={{ display: 'flex', alignItems: 'center', width: '480px', justifyContent: 'space-between' }}>
            {
              userCreationOptions?.map(({ id, label }) => (
                <CheckBoxWithIcon
                  key={id}
                  onClick={() => onChangeOptions(id, label)}
                  deSelectedIcon={ImageLinks.emptyRadioUnchecked}
                  selectedIcon={ImageLinks.yellowRadioChecked}
                  selected={selectedOptions === id}
                  label={label}
                  disabled={!userInfo.location}
                />
              ))
            }
          </div>
        </div>
        {userInfo.location && selectedOptions && !showPinModal && (
        <div>
          <div className="createUser__labelInputContainer">
            <LabelledInputWithImage
              inputStyle={invalidFields.includes('firstName') ? {
                borderColor: COLOR_CODE_NEW.PRIMARY,
                width: '320px',
              } : { width: '320px', border: '1px solid #DEDEDE', padding: '12px', fontWeight: 200, borderRadius: '5px' }}
              onChange={e => onChangeFirstName(e)}
              placeholder={t(TRANSLATIONS.ENTER_USERS_FULL_NAME)}
              label={t(TRANSLATIONS.FULL_NAME)}
              icon={userIcon}
              value={userInfo.name}
              labelStyle={{ width: '220px' }}
              errorText={t(TRANSLATIONS.ENTER_USERS_FULL_NAME)}
              showError={invalidFields.includes('firstName')}
            />
          </div>
          <div className="createUser__labelInputContainer">
            <div className="createUser__iconAndLabel">
              <img
                src={userIcon}
                alt="userIcon"
                style={{ height: '18px', width: '18px' }}
              />
              <span style={{ color: '#404040' }}>{t(TRANSLATIONS.SAP)}</span>
            </div>
            <div>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                width: '320px',
                border: '1px solid #DEDEDE',
                padding: '12px',
                borderRadius: '5px',
                marginBottom: '5px',
              }}
              >
                <input
                  type="text"
                  onChange={handleChange}
                  placeholder={t(TRANSLATIONS.ENTER_USERS_SAP)}
                  value={employeeNumber}
                  style={{ fontWeight: 200, outline: 'none', flex: 1 }}
                  maxLength={16}
                />
                {isLoadingSharpAvailability && <img src={ImageLinks.loadingIcon} height="26px" width="26px" alt="loadingIcon" />}
                {icon === 'error' && <img src={ImageLinks.redCross} height="24px" width="24px" alt="redCross" />}
                {icon === 'success' && <img src={ImageLinks.greenCheck} height="24px" width="24px" alt="greenCheck" />}
              </div>
              {message && <p style={icon === 'error' ? { color: '#C00000', fontSize: '12px' } : { color: '#56CD9D', fontSize: '12px' }}>{message}</p>}
            </div>
          </div>
          <div className="createUser__labelInputContainer">
            <LabelledInputWithImage
              inputStyle={invalidFields.includes('position') ? {
                borderColor: COLOR_CODE_NEW.PRIMARY,
                width: '320px',
              } : { width: '320px', border: '1px solid #DEDEDE', padding: '12px', fontWeight: 200, borderRadius: '5px' }}
              onChange={e => onChangePosition(e)}
              icon={userIcon}
              placeholder={t(TRANSLATIONS.SELECT_USERS_POSITION)}
              label={t(TRANSLATIONS.POSITION)}
              value={userInfo.position}
              dropdownOptions={positionsForBu || []}
              type="dropdown"
              labelStyle={{ width: '220px' }}
              errorText={t(TRANSLATIONS.SELECT_USERS_POSITION)}
              showError={invalidFields.includes('position') && !userInfo.position}
            />
          </div>
          <div className="createUser__labelInputContainer">
            <LabelledInputWithImage
              label={t(TRANSLATIONS.START_WORKING_DAY)}
              type="datepicker"
              value={selectedStartWorkingDay || null}
              onChange={handleStartWorkingDayChange}
              dateFormat="yyyy-MM-dd"
              placeholder={t(TRANSLATIONS.SELECT_HIRING_DATE)}
              inputStyle={invalidFields.includes('startWorkingDay') ? {
                borderColor: COLOR_CODE_NEW.PRIMARY,
              } : { marginTop: '0px' }}
              maxDate={new Date()}
              icon={ImageLinks.calendar}
              datePickerColor={COLOR_CODE_NEW.PRIMARY}
              showDatePickerError={invalidFields.includes('startWorkingDay')}
              labelStyle={{ width: '220px' }}
              errorText={t(TRANSLATIONS.SELECT_HIRING_DATE)}
              showError={invalidFields.includes('startWorkingDay') && !selectedStartWorkingDay}
              inputHeight='50px'
              inputWidth='320px'
              inputLabelStyle={{ top: '-2px' }}
            />
          </div>
          {showRoleOptions && (
          <>
            <div className="createUser__divider" />
            <div className="createUser__permissionsTitle">{t(TRANSLATIONS.USER_PERMISSIONS)}</div>
            {permissionsOptions.map(({ id, label }) => (
              <CheckBoxWithIcon
                key={id}
                onClick={() => onChangeRole(id)}
                deSelectedIcon={ImageLinks.emptyRadioUnchecked}
                selectedIcon={ImageLinks.yellowRadioChecked}
                selected={selectedRole === id}
                label={label}
              />
            ))}
          </>
          )}
        </div>
        )}
        <div className="createUser__bottom_container">
          <div className="createUser__bottom_container__buttons">
            <div>
              <BasicButton
                text={t(TRANSLATIONS.CANCEL)}
                onClick={closeModal}
                className="createUser__bottom_container__buttons__cancel"
              />

              <BasicButton
                text={t(TRANSLATIONS.CREATE_USER)}
                onClick={submitHandler}
                className="createUser__bottom_container__buttons__add"
                disabled={checkEmptyFields}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreateUserModal;
