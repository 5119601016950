import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import BasicButton from 'components/Button/BasicButton';
import Iframe from 'components/Iframe';
import ChartTabs from 'components/Tabs/ChartTabs';
import Modal from 'components/Modal';
import Header from 'components/Header';
import { withStyles } from '@mui/styles';
import Radio, { RadioProps } from '@mui/material/Radio';
import axios from 'axios';
import ProgressBar from 'components/ProgressBar';
import './SKAPPowerBiStyles.scss';
import { selectUser } from 'store/selectors/user.selectors';
import request from 'utils/request';
import { toast } from 'react-toastify';

const { CancelToken } = axios;

const SKAPPowerBi = () => {
  const { t } = useTranslation();
  const locationsObject = useSelector(selectLocationsToAccess);
  const BlueRadio = withStyles({
    root: {
      color: '#878787',
      '&$checked': {
        color: '#6C63FF',
      },
    },
    checked: {},
  })((MUIRadioProps: RadioProps) => <Radio color="default" {...MUIRadioProps} />);

  const skapDownloadOptions = [{
    slug: 'general-report',
    label: 'Reporte General',
  }, {
    slug: 'warehouse_montacarguista',
    label: t(TRANSLATIONS.FORKLIFT_DRIVER),
  }, {
    slug: 'warehouse_auxiliar',
    label: t(TRANSLATIONS.WAREHOUSE_AUXILIAR),
  }, {
    slug: 'delivery_conductor',
    label: t(TRANSLATIONS.DELIVERY_DRIVER),
  }, {
    slug: 'delivery_auxiliar',
    label: t(TRANSLATIONS.DELIVERY_AUXILIAR),
  }, {
    slug: 'patio_driver',
    label: t(TRANSLATIONS.PATIO_DRIVER),
  }, {
    slug: 'current-status',
    label: t(TRANSLATIONS.GRADES_CURRENT_STATUS),
  },
  ];

  const skapUploadOptions = [{
    slug: 'warehouse_montacarguista',
    label: 'Warehouse Montacarguista',
  }, {
    slug: 'warehouse_auxiliar',
    label: 'Warehouse Auxiliar',
  }, {
    slug: 'delivery_conductor',
    label: 'Delivery Conductor',
  }, {
    slug: 'delivery_auxiliar',
    label: 'Delivery Auxiliar',
  }, {
    slug: 'patio_driver',
    label: 'Patio Driver',
  }];

  const tabs = [
    { title: 'Adherencia Zona', value: '', type: 'Adherencia Zona', pageName: 'ReportSection?', index: 0 },
    { title: 'Adherencia SKAP', value: '', type: 'Adherencia SKAP', pageName: 'ReportSectionbf80177cea7e6b33848e', index: 1 },
    { title: 'Adherencia SKAP-Matriz', value: '', type: 'Adherencia SKAP- Matriz', pageName: 'ReportSection62bdc7084c0c1bacd1af', index: 2 },
    { title: 'Capabilities NOK', value: '', type: 'Capabilities NOK', pageName: 'ReportSection564617288c180647bc80', index: 3 },
    { title: 'Empleados NOK', value: '', type: 'Empleados NOK', pageName: 'ReportSectionee6b14ab27cb0a42adcb', index: 4 },
    { title: 'Tendencia mensual', value: '', type: 'Tendencia mensual', pageName: 'ReportSectionad8963b783200ba74e09', index: 5 },
    { title: 'Personal pendiente', value: '', type: 'Personal pendiente', pageName: 'ReportSectiond194a78d245910041dac', index: 6 },
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [selectedDownloadOption, setSelectedDownloadOption] = useState<string>('');
  const [selectedUploadOption, setSelectedUploadOption] = useState<string>('');
  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [downloadError, setDownloadError] = useState<boolean>(false);
  const [sentViaEmail, setSentViaEmail] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const loggedInUser = useSelector(selectUser);
  const { email: loggedInUserEmail } = loggedInUser.toJS();

  const fileRef = useRef<HTMLInputElement>(null);

  const cancelSource = useRef<any>(null);
  const isRequestCancelledManually = useRef<any>(false);

  const closeModal = () => {
    setSentViaEmail(false);
    setDownloadError(false);
    setSelectedDownloadOption('');
    setShowDownloadModal(false);
    setShowProgressBar(false);
    setProgressPercentage(0);
  };

  const onPressCancelRequest = () => {
    setSentViaEmail(false);
    setDownloadError(false);
    setShowDownloadModal(false);
    setShowProgressBar(false);
    setProgressPercentage(0);
    if (cancelSource?.current) {
      isRequestCancelledManually.current = true;
      cancelSource.current.cancel();
    }
  };

  const downloadSKAPExcel = useCallback(
    async () => {
      cancelSource.current = CancelToken.source();
      try {
        setSentViaEmail(false);
        setDownloadError(false);
        setShowProgressBar(true);
        const url = `/api/v2/skap/excel?locations=${JSON.stringify(locationsObject)}&type=${selectedDownloadOption}`;
        const res = await axios({
          url,
          method: 'GET',
          onDownloadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgressPercentage(percentCompleted);
          },
          cancelToken: cancelSource.current.token,
        });
        if (res.data?.sentViaEmail) {
          return setSentViaEmail(true);
        }
        const downloadUrl = res.data;
        const a = document.createElement('a');
        a.href = downloadUrl;
        setSelectedDownloadOption('');
        setShowDownloadModal(false);
        setShowProgressBar(false);
        setProgressPercentage(0);
        a.click();
      } catch (error) {
        if (!isRequestCancelledManually.current) {
          setDownloadError(true);
          setProgressPercentage(0);
          isRequestCancelledManually.current = false;
        }
      }
    }, [locationsObject, selectedDownloadOption],
  );

  const onClickDownload = () => {
    setShowDownloadModal(true);
  };

  const closeUploadModal = () => {
    setShowUploadModal(false);
  };

  const selectDownloadOption = (downloadOptionSlug: any) => {
    setSelectedDownloadOption(downloadOptionSlug);
  };

  const selectUploadOption = (option: any) => {
    setSelectedUploadOption(option);
  };

  const onClickUpload = () => {
    setShowUploadModal(true);
  };

  const handleAttachmentsUpload = async (e: any) => {
    setLoading(true);
    const { files } = e.target;
    if (files?.length) {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      const data = new FormData();

      data.set('file', files[0]);
      data.set('matrixType', selectedUploadOption);

      try {
        await request.post('/api/v1/skap/upload-checklist-excel', data, config);
        toast.success('Uploaded Successfully!');
        closeUploadModal();
      } catch (err) {
        toast.error('Error while uploading');
      } finally {
        setLoading(false);
        if (fileRef.current !== null) {
          fileRef.current.value = '';
        }
      }
    }
  };

  const handleChecklistExcelUpload = () => {
    if (fileRef.current !== null) {
      fileRef.current.click();
    }
  };

  return (
    <div className="skap">
      {showDownloadModal && (
        <Modal
          modalContainerStyles={{
            width: '70vw',
            left: '20%',
            maxHeight: '90vh',
            minHeight: '20vh',
            height: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <div className="skap__downloadMoal">
            <p className="skap__downloadMoal__title">{downloadError ? `${t(TRANSLATIONS.SOMETHING_WENT_WRONG)}...` : t(TRANSLATIONS.DOWNLOAD_DATA)}</p>
            {showProgressBar ? (
              <>
                <p className="skap__downloadMoal__downloading_title">{downloadError ? t(TRANSLATIONS.DOWNLOAD_FAIL_RETRY_THE_DOWNLOAD_PLEASE) : sentViaEmail ? t(TRANSLATIONS.THE_DATA_IS_TAKING_A_LONG_TIME_TO_DOWNLOAD_A_DOWNLOAD_LINK_WILL_BE_SENT_TO_YOUR_EMAIL_TO_CONTINUE) : t(TRANSLATIONS.DOWNLOADING_DATA)}</p>
                {!downloadError && !sentViaEmail && (
                  <div className="skap__downloadMoal__progress_bar">
                    <p className="skap__downloadMoal__progress_bar__title">{progressPercentage}%</p>
                    <ProgressBar bgcolor="#4472C4" height={16} progress={progressPercentage} />
                  </div>
                )}
              </>
            ) : (
              <>
                <p className="skap__downloadMoal__description">{t(TRANSLATIONS.THE_DATA_FOR_THE_LOCATIONS_THAT_ARE_FILTERED_IN_THE_TOP_FILTER_BAR_WILL_BE_DOWNLOADED)}</p>
                <Header showLogo={false} showSettings={false} />
                <div className="skap__downloadMoal__select_container">
                  <p className="skap__downloadMoal__select_container__title">{t(TRANSLATIONS.SELECT_DOCUMENT)}</p>
                  <p className="skap__downloadMoal__select_container__description">{t(TRANSLATIONS.SELECT_THE_DOCUMENT_YOU_WANT_TO_DOWNLOAD)}</p>
                  {Object.keys(skapDownloadOptions).map((downloadOption: any) => (
                    <div onChange={() => selectDownloadOption(skapDownloadOptions[downloadOption].slug)} className="skap__downloadMoal__select_container__radio_container">
                      <BlueRadio
                        checked={
                          selectedDownloadOption === skapDownloadOptions[downloadOption].slug
                        }
                        value="c"
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'C' }}
                      />
                      <p className="skap__downloadMoal__select_container__radio_container__radio_title">{skapDownloadOptions[downloadOption].label}</p>
                    </div>
                  ))}
                </div>
              </>
            )}
            <div className="skap__downloadMoal__button_container">
              {!sentViaEmail && (
                downloadError ? (
                  <BasicButton text={t(TRANSLATIONS.RETRY_AGAIN)} className="skap__downloadMoal__button_container__download_button" onClick={downloadSKAPExcel} />
                ) : (
                  <BasicButton disabled={selectedDownloadOption === '' || showProgressBar} text={t(TRANSLATIONS.DOWNLOAD)} className="skap__downloadMoal__button_container__download_button" onClick={downloadSKAPExcel} />
                )
              )}
              <BasicButton text={t(TRANSLATIONS.CANCEL)} className="skap__downloadMoal__button_container__cancel_button" onClick={showProgressBar ? onPressCancelRequest : closeModal} />
            </div>
          </div>
        </Modal>
      )}
      <div className="skap__chart_header">
        <h4 className="skap__chart_header__title">SKAP</h4>
        <div className="skap__chart_header__filters">
          {loggedInUserEmail.includes('arvolution') && (
            <BasicButton text="Upload Checklist" className="skap__chart_header__filters__upload_button" onClick={onClickUpload} />
          )}
          <BasicButton text={t(TRANSLATIONS.DOWNLOAD_DATA)} className="skap__chart_header__filters__download_button" onClick={onClickDownload} />
        </div>
      </div>
      <ChartTabs
        tabsItems={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab as any}
        selectedTabStyle="skap__selected-tab"
        customStyle="skap__tabs-container"
      />
      <Iframe
        iframe="https://app.powerbi.com/view?r=eyJrIjoiMzkyMDJkNjgtNGJiMi00NjJhLTljMTctN2QxNzY0ODdjNDA2IiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
        iframeTitle="SKAP"
        pageName={selectedTab.pageName}
      />
      {showUploadModal && (
        <Modal
          modalContainerStyles={{
            width: '70vw',
            left: '20%',
            maxHeight: '90vh',
            minHeight: '20vh',
            height: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
          onOverlayClick={closeUploadModal}
        >
          <div className="skap__downloadMoal">
            <p className="skap__downloadMoal__title">Upload Checklist</p>
            {/* <p className="skap__downloadMoal__description"></p> */}
            <div className="skap__downloadMoal__select_container">
              <p className="skap__downloadMoal__select_container__title">Select Matrix</p>
              <p className="skap__downloadMoal__select_container__description">Select Matrix for which you want to upload</p>
              {Object.keys(skapUploadOptions).map((uploadOption: any) => (
                <div key={uploadOption.slug} onChange={() => selectUploadOption(skapUploadOptions[uploadOption].slug)} className="skap__downloadMoal__select_container__radio_container">
                  <BlueRadio
                    checked={
                      selectedUploadOption === skapUploadOptions[uploadOption].slug
                    }
                    value="c"
                    name="radio-button-demo"
                    inputProps={{ 'aria-label': 'C' }}
                  />
                  <p className="skap__downloadMoal__select_container__radio_container__radio_title">{skapUploadOptions[uploadOption].label}</p>
                </div>
              ))}
            </div>

            <div className="skap__downloadMoal__button_container">
              <BasicButton disabled={selectedUploadOption === '' || loading} text="Upload" className="skap__downloadMoal__button_container__download_button" onClick={handleChecklistExcelUpload} />
              <BasicButton text={t(TRANSLATIONS.CANCEL)} className="skap__downloadMoal__button_container__cancel_button" onClick={closeUploadModal} />
            </div>
          </div>
        </Modal>
      )}
      <input
        ref={fileRef}
        className="hidden"
        type="file"
        onChange={handleAttachmentsUpload}
        name="file"
        multiple
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
    </div>
  );
};

export default SKAPPowerBi;
